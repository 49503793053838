import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Spinner
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  ReactDataTable,
} from "../../components/Component";

import DataTable from "react-data-table-component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import { GetApiLink, getRequestConfig } from "../../utils/Utils";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
  	<input
    	id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button className="close" onClick={onClear}>
      <Icon name="cross-sm" />
    </button>
    </>
  );
const JobInwardList = () => {
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const history = useHistory();
  const [JobInwardList, setJobInwardList] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  function fetchJobInwardList() {
    axios
      .get(`${GetApiLink()}api/jobinwardlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Job Inward List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        const timeout = setTimeout(() => {
          setJobInwardList(response.data);
          setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Job Inward List Error: ", error);
      });
  }
  function handleInwardConvert(inwId){
    axios
      .get(`${GetApiLink()}api/convertjobinward/${inwId}`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Job Inward Convert Get: ", response);
        if (response.data.error === "false") {
          swal("Error", `Opps Something went wrong!!!`, "error");
        } else {
          swal({
            icon: "success",
            title: "Update",
            text: response.data.message,
            type: "success",
          }).then(function () {
            fetchJobInwardList();
            //history.push(`${process.env.PUBLIC_URL}/view/Inward/JobInwardList`);
          });
        }
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Job Inward Convert Error: ", error);
      });
  }
  useEffect(() => {
    fetchJobInwardList();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  const dataTableColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      grow:1
    },
    {
      name: "Job Inward Date",
      selector: (row) => moment(row.jobDate).format('DD-MM-YYYY'),
      sortable: true,
      grow:2
    },
    {
      name: "Job Inward No",
      selector: (row) => row.jobNo,
      sortable: true,
      grow:2
    },
    {
      name: "Client Name",
      selector: (row) => row.customerName,
      sortable: true,
      grow:4
    },
    {
      Header: "Action",
      accessor: "action",
      grow:4,
      cell: (row) => (
        <>
        <div data-tag="allowRowEvents">
          <Button color="primary" size="sm" aria-hidden="true" onClick={(e) => onRowClick(e, row.id,"view")}>
            View
          </Button>
        </div>
        <div data-tag="allowRowEvents">
          <Button color="primary" size="sm" aria-hidden="true" onClick={(e) => onRowClick(e, row.id,"edit")}>
            Edit
          </Button>
        </div>
        <div data-tag="allowRowEvents">
          <Button color="primary" size="sm" aria-hidden="true" onClick={(e) => onRowConvertClick(e,row.id)}>
            Convert
          </Button>
        </div>
      </>
      ),
    },
  ];
	const filteredItems = JobInwardList && JobInwardList.filter(
		item => item.customerName && item.customerName.toLowerCase().includes(filterText.toLowerCase()),
	);

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);
  const handleButtonClick = (e, row) => {
    alert(row);
  };
  const onRowClick = (e, v,type) => {
    if(type == 'edit'){
      history.push(`${process.env.PUBLIC_URL}/view/Inward/JobInward/edit/${v}`);
    }else if(type == 'view'){
      history.push(`${process.env.PUBLIC_URL}/view/Inward/JobInward/view/${v}`);
    }
  };
  const onRowConvertClick = (e, v) => {
    handleInwardConvert(v);
  };
  const AddNewData = (e) => {
    history.push(`${process.env.PUBLIC_URL}/view/Inward/JobInward/add/0`);
  };
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              {/* <div className={`code-block code-block-clean }`}>
              <OverlineTitle className="title">{"Job Inward List"}</OverlineTitle>
              <Button
                  color="primary"
                  onClick={(e) => {
                    AddNewData(e);
                  }}
                  size="sm"
                  className="clipboard-init"
                  style={{ backgroundColor: "#854fff" }}
                >
                  {"Add New"}
                </Button>
                </div> */}
              <Block size="lg">
                <PreviewCard>
                <DataTable
                  title="Job Inward List"
                    data={filteredItems}
                    columns={dataTableColumns}
                    progressPending={pending}
                    progressComponent={<Button disabled color="primary">
                    <Spinner size="sm" />
                    <span> Loading... </span>
                  </Button>
                  }
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    actions={<Button
                      color="primary"
                      onClick={(e) => {
                        AddNewData(e);
                      }}
                      size="sm"
                      className="clipboard-init"
                      style={{ backgroundColor: "#854fff" }}
                    >
                      {"Add New"}
                    </Button>}
                  />
                {/* <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Job Inward Date</th>
                        <th>Job Inward No</th>
                        <th>Customer Name</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {JobInwardList.map((d) => {
                        return (
                          <tr>
                            <td>{d.id}</td>
                            <td>{moment(d.jobDate).format('DD-MM-YYYY')}</td>
                            <td>{d.jobNo}</td>
                            <td>{d.customerName}</td>
                            <td>{d.status}</td>
                            <td>
                              <Button
                                color="primary"
                                size="sm"
                                className="clipboard-init"
                                style={{ backgroundColor: "#854fff" }}
                                onClick={(e) => {
                                  onRowClick(e, d.id, "edit");
                                }}
                              >
                                Edit
                              </Button> 
                              <Button
                                color="primary"
                                size="sm"
                                className="clipboard-init"
                                style={{ backgroundColor: "#854fff" }}
                                onClick={(e) => {
                                  onRowConvertClick(e,d.id);
                                }}
                              >
                                Convert
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table> */}
                </PreviewCard>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default JobInwardList;
