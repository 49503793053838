import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  ReactDataTable,
} from "../../components/Component";

import DataTable from "react-data-table-component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import moment from "moment";
import { GetApiLink, getRequestConfig } from "../../utils/Utils";
import axios from "axios";
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button className="close" onClick={onClear}>
      <Icon name="cross-sm" />
    </button>
  </>
);
const SampleInwardList = () => {
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const history = useHistory();

  const [pending, setPending] = React.useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [getSampleInwardList, setSampleInwardList] = useState([]);
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  function fetchSampleInwardList() {
    axios
      .get(`${GetApiLink()}api/sampleinwardlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Sample Inward List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        const timeout = setTimeout(() => {
          setSampleInwardList(response.data);
          setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);

        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Sample Inward List Error: ", error);
      });
  }
  useEffect(() => {
    fetchSampleInwardList();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  const dataTableColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Job Inward No",
      selector: (row) => row.jobNo,
      sortable: true,
      grow: 3,
    },

    {
      name: "Sample Inward Date",
      selector: (row) => moment(row.inwDate).format("DD-MM-YYYY"),
      sortable: true,
      grow: 3,
    },
    {
      name: "Sample Inward No",
      selector: (row) => row.inwNo,
      sortable: true,
      grow: 4,
    },
    {
      name: "Client Name",
      selector: (row) => row.customerName,
      sortable: true,
      grow: 5,
    },
    {
      Header: "Action",
      accessor: "action",
      grow: 2,
      cell: (row) => (
        <>
          <div data-tag="allowRowEvents">
            <Button
              className="btn-round btn-icon"
              color="primary"
              size="sm"
              onClick={(e) => onRowClick(e, row.id, "view")}
            >
              <Icon name="eye" />
            </Button>
          </div>
          <div data-tag="allowRowEvents">
            <Button
              className="btn-round btn-icon"
              color="primary"
              size="sm"
              onClick={(e) => onRowClick(e, row.id, "edit")}
            >
              <Icon name="pen2" />
            </Button>
          </div>
          <div data-tag="allowRowEvents">
            <Button
              className="btn-round btn-icon"
              color="primary"
              size="sm"
              onClick={(e) => onRowClick(e, row.id, "data")}
            >
              <Icon name="list" />
            </Button>
          </div>
          <div data-tag="allowRowEvents">
            <Button
              className="btn-round btn-icon"
              color="primary"
              size="sm"
              onClick={(e) => onRowClick(e, row.id, "add")}
            >
              <Icon name="filter-fill" />
            </Button>
          </div>
        </>
      ),
    },
  ];
  const filteredItems =
    getSampleInwardList &&
    getSampleInwardList.filter(
      (item) => item.customerName && item.customerName.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);
  const handleButtonClick = (e, row) => {
    alert(row);
  };
  const onRowClick = (e, v, type) => {
    if (type == "add") {
      history.push(`${process.env.PUBLIC_URL}/view/Inward/SampleInward/add/${v}`);
    } else if (type == "edit") {
      history.push(`${process.env.PUBLIC_URL}/view/Inward/SampleInward/edit/${v}`);
    } else if (type == "data") {
      history.push(`${process.env.PUBLIC_URL}/view/Inward/DataSheet/add/${v}`);
    } else if (type == "view") {
      history.push(`${process.env.PUBLIC_URL}/view/Inward/SampleInward/view/${v}`);
    }
  };
  // const AddNewData = (e) => {
  //   history.push(`${process.env.PUBLIC_URL}/view/Inward/SampleInward/add/0`);
  // };
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              {/* <div className={`code-block code-block-clean }`}>
              <OverlineTitle className="title">{"Sample Inward List"}</OverlineTitle>
              <Button
                  color="primary"
                  onClick={(e) => {
                    AddNewData(e);
                  }}
                  size="sm"
                  className="clipboard-init"
                  style={{ backgroundColor: "#854fff" }}
                >
                  {"Add New"}
                </Button>
                </div> */}
              <Block size="lg">
                <DataTable
                  title="Sample Inward List"
                  data={filteredItems}
                  columns={dataTableColumns}
                  progressPending={pending}
                  progressComponent={<Spinner style={{ width: "3rem", height: "3rem" }} />}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default SampleInwardList;
