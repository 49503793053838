import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import classnames from "classnames";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataCard from "../../components/partials/default/DataCard";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  CardLink,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  Button,
} from "../../components/Component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import ReactInputDateMask from "react-input-date-mask";
import { GetApiLink, getRequestConfig, getUser, str2bytes } from "../../utils/Utils";
import swal from "sweetalert";
import axios from "axios";
import InputMask from "react-input-mask";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import DataTable from "react-data-table-component";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { saveAs } from "file-saver";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));
const DataSheet = (props) => {
  const { match, history } = props;
  const { id, type } = match.params;
  const classes = useStyles();
  const [PdfLoading, setPdfLoading] = useState(false);
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [modelParaForm, setParaForm] = useState(false);
  const toggleParaTestModel = () => setParaForm(!modelParaForm);
  const [suggestion, setSuggestion] = useState([]);
  const [suggestionParaList, setSuggestionParaList] = useState([]);
  const [suggestionParaFormula, setSuggestionParaFormula] = useState([]);
  // function to change the design view under 990 px
  const [FormState, setFormState] = useState({
    values: {
      setId: "0",
      printType: "FormatOne",
      remark: "",
      paradetail: [],
      paraInputDetail: [],
    },
  });
  const [MaterialList, setMaterialist] = useState([]);
  const [valid, setValid] = useState(false);
  const [date, setDate] = useState();
  const [getResult, setResult] = useState([]);
  const [getSampleIds, setSampleIds] = useState([]);
  const [getSampleResult, setSampleResult] = useState([]);
  const [getMaterialHead, setMaterialHead] = useState([]);
  const [getSummaryResult, setSummaryResult] = useState([]);
  const [getFileName, setFileName] = useState("");
  let keysPressed = {};
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  const SoShow = (testId) => {
    setPdfLoading(true);
    // if (id === "") {
    //   //setPdfLoading(false);
    //   history.push(`${process.env.PUBLIC_URL}/view/Inward/DataSheetList`);
    // }
    let params = {
      testId: testId,
      fetch: "test_detail",
    };
    axios
      .get(
        `${GetApiLink()}api/datasheetcolumn`,
        getRequestConfig({
          params: params,
        })
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Data Sheet Show Get: ", response);
        //console.log('A',response.data.response.error);
        if (typeof response.data.error != "undefined" && response.data.error == "true") {
          swal({
            icon: "error",
            title: "Opps!!!",
            text: response.data.message,
            type: "error",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Inward/SampleInwardList`);
          });
        } else {
          if (type === "add") {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ...response.data.data,
              },
            }));
            // setSuggestion(response.data.formula_data);
            // setSuggestionParaList(response.data.para_data);
            // setSuggestionParaFormula(response.data.fdata);
            // let tempParaArray = response.data.fTempdata;
            // setMaterialist(tempParaArray);
          } else {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ...response.data.data[0],
                // paradetail,
                // headerDetail
              },
            }));
          }
          //}
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Data Sheet Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  };
  const fetchTestDetail = (testId, mId, setId) => {
    setPdfLoading(true);
    // if (id === "") {
    //   //setPdfLoading(false);
    //   history.push(`${process.env.PUBLIC_URL}/view/Inward/DataSheetList`);
    // }
    let params = {
      testId: testId,
      mId: mId,
      setId: setId,
      fetch: "para_detail",
    };
    axios
      .get(
        `${GetApiLink()}api/datasheetcolumn`,
        getRequestConfig({
          params: params,
        })
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Data Sheet Show Get: ", response);
        //console.log('A',response.data.response.error);
        if (typeof response.data.error != "undefined" && response.data.error == "true") {
          swal({
            icon: "error",
            title: "Opps!!!",
            text: response.data.message,
            type: "error",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Inward/SampleInwardList`);
          });
        } else {
          setSuggestion(response.data.formula_data);
          setSuggestionParaList(response.data.para_data);
          setSuggestionParaFormula(response.data.fdata);
          let tempParaArray = response.data.fTempdata;
          setMaterialist(tempParaArray);
          //}
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Data Sheet Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
  };
  const handleFocus = (event) => event.target.select();
  const handleResult = (event, index, pId) => {
    //console.log("Viral ", event.target.name, event.currentTarget.value);
    const tempParaArray =
      suggestionParaList &&
      suggestionParaList.filter((el) => {
        return el.paraId == pId;
      });
    const tempArray = tempParaArray.map((t) => {
      return t.mVariable;
    });
    // const temp = tempArray.map((el) => {
    //   return {
    //     paraId: pId,
    //     index: event.target.name,
    //     value: event.target.value,
    //   };
    // });
    const temp = suggestionParaFormula.filter((el) => {
      if (el.formula_name == event.target.name && el.paraId == pId) {
        el.paraId = pId;
        el.value = event.target.value;
      }
    });
    setResult(temp);
  };
  const handleSaveEntry = () => {
    let output = [];
    console.log("getSummaryResult ", getSummaryResult);
    const tempResult =
      getSummaryResult &&
      getSummaryResult.filter((el) => {
        return el.value != 0;
      });
    tempResult.forEach(function (item) {
      let existing = output.filter(function (v, i) {
        return v.paraId == item.paraId;
      });
      if (existing.length) {
        let existingIndex = output.indexOf(existing[0]);
        output[existingIndex].value = output[existingIndex].value.concat(item.value);
      } else {
        if (typeof item.value == "string") item.value = [item.value];
        output.push(item);
      }
    });
    // const fianlResultArray =
    //   tempResult &&
    //   tempResult.map((l) => {
    //     return {
    //       jobId: l.jobId,
    //       inwId: l.inwId,
    //       paraId: l.paraId,
    //       formula_name: l.formula_name,
    //       value: l.value,
    //     };
    //   });
    let params = {
      setId: FormState.values.setId,
      resultD1: getResult,
      summaryD1: output,
      remark: FormState.values.remark,
      testId:id
    };
    setPdfLoading(true);
    const article = FormState.values;

    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    if (type === "edit") {
      axios
        //.put(`${GetApiLink()}/api/soedit/${id}`, article, { headers })
        .put(`${GetApiLink()}api/sampleinward/edit`, article, { headers })
        .then((response) => {
          setPdfLoading(false);
          console.log("Sample Inward Update Save request :", response);
          if (response.data.validate === "false") {
            swal(response.data.message);
            return;
          } else if (response.data.error === "true") {
            swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
          } else {
            swal({
              icon: "success",
              title: "Update",
              text: response.data.message,
              type: "success",
            }).then(function () {
              history.push(`${process.env.PUBLIC_URL}/view/Master/CustomerMaster/view/${FormState.values.id}`);
            });
          }
        })
        .catch((error) => {
          setPdfLoading(false);
          console.log("Errors LOG", error);
        });
    } else if (type === "add") {
      axios
        .post(`${GetApiLink()}api/datasheet/add`, params, { headers })
        .then((response) => {
          setPdfLoading(false);
          console.log("Sample Inward Save request :", response);
          if (response.data.validate === "false") {
            swal(response.data.message);
            return;
          } else if (response.data.error === "false") {
            swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
          } else {
            swal({
              icon: "success",
              title: "Done",
              text: response.data.message,
              type: "success",
            }).then(function () {
              fetchTestDetail(id, FormState.values.mId, FormState.values.setId);
              //setFinalResult(response.data.result[0]);
              //toggleParaTestModel();
              //window.location.reload();
            });
          }
        })
        .catch((error) => {
          setPdfLoading(false);
          console.log("Errors LOG", error);
        });
    }
    //}
    //}
  };
  const handleReportLoad = () => {
    let params = {
      inwId: id,
      jobId: FormState.values.jobId,
      mId: FormState.values.mId,
    };
    setPdfLoading(true);
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    axios
      .post(
        `${GetApiLink()}api/showReport`,
        // getRequestConfig({
        //   params: params,
        // })
        params,
        { headers }
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Data Sheet Show Get: ", response);
        setSampleIds(response.data.sampleNo);
        setSampleResult(response.data.finalArray);
        setMaterialHead(response.data.materilDetail);
        if (response.data.hasOwnProperty("downloadfile")) {
          setFileName(response.data.downloadfile.filename);
          // setFileName1(response.data.response.filename1);
          //if (response.data.response.export == 'pdf') {
          let decoded = base64_decode(response.data.downloadfile.filedata);
          var blob = new Blob([str2bytes(decoded)], {
            type: "application/pdf",
          });
          saveAs(blob, response.data.downloadfile.filename);
          var blobURL = URL.createObjectURL(blob);
          window.open(`${blobURL}`, "_blank", "noopener noreferrer");
          // } else if (response.data.response.export == 'excel') {
          //   let decoded = base64_decode(response.data.response.filedata);
          //   var blob = new Blob([str2bytes(decoded)], {
          //     type:
          //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          //   });
          //   saveAs(blob, response.data.response.filename);
          // }
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Data Sheet Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
  };
  const handleFinalReportLoad = () => {
    let params = {
      inwId: id,
      jobId: FormState.values.jobId,
      mId: FormState.values.mId,
      printType: FormState.values.printType,
    };
    setPdfLoading(true);
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    axios
      .post(
        `${GetApiLink()}api/showFinalReport`,
        // getRequestConfig({
        //   params: params,
        // })
        params,
        { headers }
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Final Report Show Get: ", response);
        setMaterialHead(response.data.materilDetail);
        if (response.data.hasOwnProperty("downloadfile")) {
          setFileName(response.data.downloadfile.filename);
          // setFileName1(response.data.response.filename1);
          //if (response.data.response.export == 'pdf') {
          let decoded = base64_decode(response.data.downloadfile.filedata);
          var blob = new Blob([str2bytes(decoded)], {
            type: "application/pdf",
          });
          saveAs(blob, response.data.downloadfile.filename);
          var blobURL = URL.createObjectURL(blob);
          window.open(`${blobURL}`, "_blank", "noopener noreferrer");
          // } else if (response.data.response.export == 'excel') {
          //   let decoded = base64_decode(response.data.response.filedata);
          //   var blob = new Blob([str2bytes(decoded)], {
          //     type:
          //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          //   });
          //   saveAs(blob, response.data.response.filename);
          // }
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Final Report Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
  };
  function handleChange(event) {
    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        [event.target.name]: event.target.value,
      },
    }));
    if (event.target.name == "setId") {
      setFormState((FormState) => ({
        ...FormState,
        values: {
          ...FormState.values,
          ["setId"]: event.target.value,
        },
      }));
      setSuggestion([]);
      setSuggestionParaList([]);
      setSuggestionParaFormula([]);
      setMaterialist([]);
      fetchTestDetail(id, FormState.values.mId, event.currentTarget.value);
    }
  }
  function handleEnter(event, fName, pId, pName) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
        const tempParaArray =
          suggestionParaList &&
          suggestionParaList.filter((el) => {
            return el.paraId == pId;
          });
        const tempArray = tempParaArray.map((t) => {
          return t.mVariable;
        });
        const temp = suggestionParaFormula.map((el) => {
          if (el.formula_name == event.target.name && el.paraId == pId) {
            el.paraId = pId;
            el.value = event.target.value;
          }
          return el;
        });
        const tempTwo = MaterialList.map((el) => {
          if (el.formula_name == event.target.name && el.paraId == pId) {
            el.paraId = pId;
            el.value = event.target.value;
            el.fName = pName;
          }
          return el;
        });
        setResult(temp);
        setSummaryResult(tempTwo);
      }
    }
  }
  let testInput = [];

  useEffect(() => {
    if (type === "edit" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "view" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "add") {
      SoShow(id);
    }
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  useEffect(() => {
    const regexddmmyyyy = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;

    if (regexddmmyyyy.test(date)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [date]);
  useEffect(() => {
    if (valid == false) {
      //swal('Please enter valid date format');
    }
  }, [valid]);
  const CheckData = () => {
    setPdfLoading(false);
  };

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <OverlineTitle tag="span" className="preview-title-lg">
                {" "}
                Data Sheet
              </OverlineTitle>
              <form>
                <TabContent activeTab={activeTab}>
                  <Row className="gy-4">
                    <Col sm="12">
                      <table border={1} cellPadding={5} width={"100%"}>
                        <tr style={{ color: "#000" }}>
                          <td style={{ width: "25%" }}>Job No :</td>
                          <td style={{ width: "30%" }}>{FormState.values.jobNo}</td>
                          <td style={{ width: "25%" }}>Inward No :</td>
                          <td style={{ width: "30%" }}>{FormState.values.inwardNo}</td>
                        </tr>
                        <tr style={{ color: "#000" }}>
                          <td style={{ width: "25%" }}>PO/Ref. No :</td>
                          <td style={{ width: "30%" }}>{FormState.values.refNo}</td>
                          <td style={{ width: "25%" }}>Grade :</td>
                          <td style={{ width: "30%" }}>{FormState.values.grade}</td>
                        </tr>
                        <tr style={{ color: "#000" }}>
                          <td style={{ width: "25%" }}>Making / Batch No. / Type / Dia :</td>
                          <td style={{ width: "30%" }}>{FormState.values.batchNo}</td>
                          <td style={{ width: "25%" }}>Sample Condition :</td>
                          <td style={{ width: "30%" }}>{FormState.values.sampleCondition}</td>
                        </tr>
                        <tr style={{ color: "#000" }}>
                          <td style={{ width: "25%" }}>Sample Collected By :</td>
                          <td style={{ width: "20%" }}>{FormState.values.sampleBy}</td>
                          <td style={{ width: "25%" }}>Expected Date of Reporting :</td>
                          <td style={{ width: "20%" }}>{moment(FormState.values.rptDate).format("DD-MM-YYYY")}</td>
                        </tr>
                        <tr style={{ color: "#000" }}>
                          <td>Material Type :</td>
                          <td style={{ width: "20%" }}>{FormState.values.mName}</td>
                          <td>Material Type :</td>
                          <td style={{ width: "20%" }}>
                            <select
                              name="setId"
                              style={{ width: "100%", height: 25 }}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            >
                              <option value="0">Select Test</option>
                              {(() => {
                                const options = [];
                                for (let i = 1; i <= FormState.values.totalSample; i++) {
                                  options.push(<option value={i}>{`Sample - ${i}`}</option>);
                                }
                                return options;
                              })()}
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <textarea
                              name="remark"
                              style={{ width: "100%" }}
                              placeholder="Remark"
                              rows={5}
                              value={FormState.values.remark}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </td>
                          <td colspan="2">
                            <Col sm="12">
                              <Button
                                color="success"
                                name="Save"
                                type="button"
                                style={{ marginRight: "5%" }}
                                onClick={(e) => {
                                  handleSaveEntry(e);
                                }}
                              >
                                Save Data
                              </Button>

                              <Button
                                color="success"
                                name="Save"
                                type="button"
                                style={{ marginRight: "5%" }}
                                onClick={(e) => {
                                  handleReportLoad(e);
                                }}
                              >
                                Datasheet Report
                              </Button>

                              <Button
                                color="success"
                                name="Save"
                                type="button"
                                onClick={(e) => {
                                  handleFinalReportLoad(e);
                                }}
                              >
                                Final Report
                              </Button>
                              <br />
                              <br />
                              <FormGroup>
                                <div className="preview-block">
                                  <span
                                    className="preview-title overline-title"
                                    style={{ fontWeight: "bold", color: "red" }}
                                  >
                                    Final Report Print Type:
                                  </span>
                                  <div className="g-4 align-center flex-wrap">
                                    <div className="g">
                                      <div className="custom-control custom-control-sm custom-radio">
                                        <input
                                          type="radio"
                                          disabled={type == "view" ? true : false}
                                          className="custom-control-input form-control"
                                          name="printType"
                                          id="customRadio7"
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                          checked={FormState.values.printType == "FormatOne"}
                                          value={"FormatOne"}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadio7">
                                          Pre-Printed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="g">
                                      <div className="custom-control custom-control-sm custom-radio">
                                        <input
                                          type="radio"
                                          disabled={type == "view" ? true : false}
                                          className="custom-control-input form-control"
                                          name="printType"
                                          id="customRadio8"
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                          value={"FormatTwo"}
                                          checked={FormState.values.printType == "FormatTwo"}
                                        />
                                        <label className="custom-control-label" htmlFor="customRadio8">
                                          Plain-Print
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row className="gy-4">
                    <Col sm="12" style={{ width: "200px", overflowX: "scroll" }}>
                      <table
                        border={1}
                        cellPadding={5}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        <tr>
                          <td></td>
                          {suggestion.map((s) => {
                            return <td style={{ width: "200px" }}>{s.mDesc}</td>;
                          })}
                          <td></td>
                        </tr>
                        <tr>
                          <td>{"Parameter"}</td>
                          {suggestion.map((s) => {
                            return <td style={{ width: "200px" }}>{s.mVariable}</td>;
                          })}
                          <td>Result</td>
                        </tr>
                        {suggestionParaList.map((posFields, POSFieldsId) => {
                          return (
                            <>
                              <tr>
                                <td key={POSFieldsId} value={posFields.paraName}>
                                  {posFields.paraName}{" "}
                                </td>
                                {posFields.mVariable.map((headers, index) => {
                                  const tempCheck = posFields.mResult.filter((v, ind) => {
                                    return ind == index;
                                  });
                                  if (headers != "") {
                                    return (
                                      <td key={headers} style={{ width: "200px" }}>
                                        <input
                                          type="text"
                                          style={{ width: "100%" }}
                                          name={headers}
                                          onKeyDown={(e) => {
                                            handleEnter(e, headers, posFields.paraId, posFields.fName);
                                          }}
                                          // onChange={(e) => {
                                          //   handleResult(e, headers, posFields.paraId);
                                          // }}
                                          defaultValue={tempCheck}
                                          onFocus={handleFocus}
                                        />
                                      </td>
                                    );
                                  } else {
                                    return <td style={{ width: "200px" }}>0</td>;
                                  }
                                })}
                                <td>{parseFloat(posFields.result).toFixed(2)}</td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </Col>
                    <Col sm="12" style={{ display: "none" }}>
                      <table
                        border={1}
                        cellPadding={5}
                        width={"100%"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "#000",
                        }}
                      >
                        <tr>
                          <td></td>
                          {(() => {
                            const headers = [];
                            for (let r = 1; r <= FormState.values.totalSample; r++) {
                              headers.push(<td value={r}>{`Sample - ${r}`}</td>);
                            }
                            return headers;
                          })()}
                          <td>Result</td>
                        </tr>

                        {Object.keys(getSampleResult).map((el, i) => {
                          let l = 0;
                          let sum = 0;
                          let avg = 0;
                          return (
                            <>
                              <tr>
                                <td>{el}</td>
                                {(() => {
                                  let td = [];
                                  for (let k = 1; k <= Object.keys(Object.values(getSampleResult)[i]).length; k++) {
                                    sum += parseFloat(Object.values(getSampleResult)[i][k]);
                                    td.push(<td key={i}>{Object.values(getSampleResult)[i][k]}</td>);
                                    l = k;
                                  }
                                  avg = parseFloat(sum) / l;
                                  return td;
                                })()}
                                <td>{parseFloat(avg).toFixed(4)}</td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </Col>
                  </Row>
                </TabContent>

                <Backdrop className={classes.backdrop} open={PdfLoading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </form>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default DataSheet;
