import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  ReactDataTable,
} from "../../components/Component";

import DataTable from "react-data-table-component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import { GetApiLink, getRequestConfig } from "../../utils/Utils";
import axios from "axios";
const PaymentMasterList = () => {
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [PaymentMasterList, setPaymentMasterList] = useState([]);
  const history = useHistory();
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  function fetchPaymentList() {
    axios
      .get(`${GetApiLink()}api/paymentmasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Payment Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setPaymentMasterList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Payment Master List Error: ", error);
      });
  }
  useEffect(() => {
    fetchPaymentList()
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  const handleButtonClick = (e, row) => {
    alert(row);
  };
  const dataTableColumns = [
    {
      name: "SRNO",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Material Test Name",
      selector: (row) => row.testname,
      sortable: true,
      hide: 370,
    },
    {
      name: "Test Group",
      selector: (row) => row.testgroup,
      sortable: true,
      hide: "sm",
    },
    {
      Header: "Action",
      accessor: "action",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <Button color="primary" size="sm" aria-hidden="true" onClick={(e) => onRowClick(e, row.id)}>
            View
          </Button>
        </div>
      ),
    },
  ];
  const DataTableData = [
    {
        id: 1,
        pName: "General",
        pTerms: "Test General Payment",
        status: 1,
        labId: 1
    },
    {
        id: 2,
        pName: "Demo Payment",
        pTerms: "Demo Payment",
        status: 1,
        labId: 1
    }
];
  const onRowClick = (e, v) => {
    history.push(`${process.env.PUBLIC_URL}/view/Master/PaymentMaster/view/${v}`);
  };
  const AddNewData = (e) => {
    history.push(`${process.env.PUBLIC_URL}/view/Master/PaymentMaster/add/0`);
  };
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <div className={`code-block code-block-clean }`}>
                <OverlineTitle className="title">{"Payment Master List"}</OverlineTitle>
                <Button
                  color="primary"
                  onClick={(e) => {
                    AddNewData(e);
                  }}
                  size="sm"
                  className="clipboard-init"
                  style={{ backgroundColor: "#854fff" }}
                >
                  {"Add New"}
                </Button>
              </div>
              <Block size="lg">
                <PreviewCard>
                  {/* <ReactDataTable
                    data={DataTableData}
                    columns={dataTableColumns}
                    expandableRows
                    pagination
                    actions
                    pointerOnHover={true}
                    highlightOnHover={true}
                  /> */}
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>SRNO</th>
                        <th>Payment Type</th>
                        
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PaymentMasterList.map((d) => {
                        return (
                          <tr>
                            <td>{d.id}</td>
                            <td>{d.pName}</td>
                            <td>
                              <Button
                                color="primary"
                                size="sm"
                                className="clipboard-init"
                                style={{ backgroundColor: "#854fff" }}
                                onClick={(e) => {
                                  onRowClick(e, d.id, "edit");
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </PreviewCard>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default PaymentMasterList;
