import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  Button,
} from "../../components/Component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import ReactInputDateMask from "react-input-date-mask";
import { GetApiLink, getRequestConfig } from "../../utils/Utils";
import swal from "sweetalert";
import axios from "axios";
import { event } from "jquery";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));
const RateMaster = (props) => {
  const { match, history } = props;
  const { id, type } = match.params;
  const classes = useStyles();
const [PdfLoading, setPdfLoading] = useState(false);
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [MaterialList, setMaterialList] = useState([]);
  const [TestMasterList, setTestMasterList] = useState([]);

  const [FormState, setFormState] = useState({
    values: {
      d1: [],
    },
  });
  function fetchMaterialList() {
    axios
      .get(`${GetApiLink()}api/materiallist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Material List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setMaterialList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Material List Get Error: ", error);
      });
  }
  function fetchTestMasterList(mId) {
    axios
      .get(`${GetApiLink()}api/testmasterlist`, getRequestConfig({ params: { mId: mId } }))
      .then((response) => {
        console.log(" Test Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setTestMasterList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Test Master List Get Error: ", error);
      });
  }

  function loadRateData() {
    // let params = {
    //   mid: FormState.values.mId,
    //   tid: FormState.values.tId,
    //   ratetype: FormState.values.RateType,
    // };
    axios
      .get(
        `${GetApiLink()}api/ratemasterlist/show/${FormState.values.mId}/${FormState.values.tId}/${
          FormState.values.RateType
        }`,
        getRequestConfig({ params: null })
      )
      .then((response) => {
        console.log(" Rate Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setFormState((FormState) => ({
          ...FormState,
          values: {
            ...FormState.values,
            d1: response.data.data,
          },
        }));
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Rate Master List Get Error: ", error);
      });
  }
  console.log(FormState.values.d1);
  let keysPressed = {};
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const CheckData = () => {
    //setPdfLoading(false);
    if (
      FormState.values.RateType === "" ||
      typeof FormState.values.RateType === "undefined" ||
      FormState.values.RateType === null ||
      FormState.values.RateType == "0"
    ) {
      swal("Please select valid Rate Type");
      return false;
    } else if (
      FormState.values.mId === "" ||
      typeof FormState.values.mId === "undefined" ||
      FormState.values.mId === null ||
      FormState.values.mId === "0"
    ) {
      swal("Please select valid materail type");
      return false;
    } else if (
      FormState.values.tId === "" ||
      typeof FormState.values.tId === "undefined" ||
      FormState.values.tId === null ||
      FormState.values.tId === "0"
    ) {
      swal("Please select test type ");
      return false;
    } else return true;
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        [event.target.name]: event.target.value,
      },
    }));
    if (event.target.name == "mId") {
      fetchTestMasterList(event.target.value);
    }
  };
  let updateRate = [];
  const handleRateChange = (event, mid, tid) => {
    updateRate =
      FormState.values.d1 &&
      FormState.values.d1.filter((el) => {
        if (el.mId == mid && el.tId == tid) {
          el.rate = event.currentTarget.value;
        }
        return el;
      });
    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        d1: updateRate,
      },
    }));
  };
  function handleEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  // function handleRateEnter(event, mid, tid, ratetype) {
  //   event.persist();
  //   const update =
  //     FormState.values.d1 &&
  //     FormState.values.d1.filter((el) => {
  //       if (el.mId == mid && el.tId == tid) {
  //         el.mId = mid;
  //         el.tId = tid;
  //         el.RateType = ratetype;
  //         el.rate = event.currentTarget.value;
  //       }
  //       return el;
  //     });
  //   setFormState((FormState) => ({
  //     ...FormState,
  //     values: {
  //       ...FormState.values,
  //       d1: update,
  //     },
  //   }));
  // }

  function handleDataSave() {
    let params = FormState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    console.log("params", updateRate, params);
    // axios
    //   .post(`${GetApiLink()}api/ratemaster/add`, params, { headers })
    //   .then((response) => {
    //     //setPdfLoading(false);
    //     console.log("Group Update Save request :", response);
    //     if (response.data.validate === "false") {
    //       swal(response.data.message);
    //       return;
    //     } else if (response.data.error === "false") {
    //       swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
    //     } else {
    //       swal("Saved successfully.", "", "success");
    //       history.push(`${process.env.PUBLIC_URL}/view/Master/TestGroupMaster/add/0`);
    //     }
    //   })
    //   .catch((error) => {
    //     //setPdfLoading(false);
    //     console.log("Errors LOG", error);
    //   });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (CheckData) {
      loadRateData();
    }
  };
  const handleSave = (event) => {
    event.preventDefault();
    handleDataSave();
  };
  const handleCancel = (event) => {
    event.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/view/Master/TestMasterList`);
  };
  useEffect(() => {
    fetchMaterialList();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  const handleFocus = (event) => event.target.select();
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-md">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <OverlineTitle tag="span" className="preview-title-lg">
                {" "}
                Material Test Master
              </OverlineTitle>
              <form>
                <Row className="gy-4">
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Rate Type :
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.RateType || ""}
                          name={"RateType"}
                        >
                          <option value="0">Select</option>
                          <option value="GOV">Goverment</option>
                          <option value="SGOV">Semi Goverment</option>
                          <option value="NGOV">None Goverment</option>
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Material Name:
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.mId || ""}
                          name={"mId"}
                        >
                          <option value="0"> Select Material Name</option>
                          {MaterialList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.mName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  {/* <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Test Name:
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.tId || ""}
                          name={"tId"}
                        >
                          <option value="0"> Select Material Name</option>
                          {TestMasterList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.tName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row className="gy-4">
                  <Col sm="2">
                    <Button
                      color="success"
                      name="Load"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Load Data
                    </Button>
                  </Col>
                  <Col sm="2">
                    <Button
                      color="success"
                      name="Save"
                      onClick={(e) => {
                        handleSave(e);
                      }}
                    >
                      Save Data
                    </Button>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="12">
                    <table className="table table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          <th>Id</th>
                          <th>Type</th>
                          <th>Material</th>
                          <th>Test</th>
                          <th>Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {FormState.values.d1 &&
                          FormState.values.d1.map((d, i) => {
                            return (
                              <tr>
                                <td scope="row">{i + 1}</td>
                                <td>{d.RateType}</td>
                                <td>{d.mName}</td>
                                <td>{d.tName}</td>
                                <td>
                                  <input
                                    disabled={type == "view" ? true : false}
                                    className="form-control form-control-sm"
                                    type="text"
                                    id="default-0"
                                    placeholder=""
                                    onChange={(e) => {
                                      handleRateChange(e, d.mId, d.tId, d.RateType);
                                    }}
                                    defaultValue={d.rate || ""}
                                    name={"rate"}
                                    onFocus={handleFocus}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </form>
              
<Backdrop className={classes.backdrop} open={PdfLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default RateMaster;
