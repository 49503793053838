import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  Button,
} from "../../components/Component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import ReactInputDateMask from "react-input-date-mask";
import { GetApiLink, getRequestConfig } from "../../utils/Utils";
import swal from "sweetalert";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));
const TermsConditionMaster = (props) => {
  const { match, history } = props;
  const { id, type } = match.params;

  const classes = useStyles();
  const [PdfLoading, setPdfLoading] = useState(false);
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [MaterialList, setMaterialist] = useState([]);
  const [FormState, setFormState] = useState({
    values: {},
  });
  let keysPressed = {};
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  // function to change the design view under 990 px
  const SoShow = (testId) => {
    setPdfLoading(true);
    // if (id === "") {
    //   //setPdfLoading(false);
    //   history.push(`${process.env.PUBLIC_URL}/view/Master/TermsConditionMasterList`);
    // }
    axios
      .get(
        `${GetApiLink()}api/termsmaster/${testId}`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Terms Condition Master Data Show Get: ", response);
        //console.log('A',response.data.response.error);
        if (typeof response.data.error != "undefined" && response.data.error == "true") {
          swal({
            icon: "error",
            title: "Opps!!!",
            text: response.data.message,
            type: "error",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Master/TermsConditionMasterList`);
          });
        } else {
          // if (response.data.response.isauth == "_AUTHFAILED") {
          //   history.push("/auth/logout");
          // } else {
          if (type != "add") {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                id: response.data.data.id,
                mId: response.data.data.mId,
                tDesc: response.data.data.tDesc,
              },
            }));
          }
          //}
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Terms Condition Master Data Show Error: ", error);
        //setCallError(`Terms Condition Master Data Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  };
  function fetchMaterialList() {
    axios
      .get(`${GetApiLink()}api/materiallist`)
      .then((response) => {
        console.log(" Material Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setMaterialist(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Material Master List Get Error: ", error);
      });
  }
  const handleSaveEntry = () => {
    setPdfLoading(true);
    if (CheckDate()) {
      setPdfLoading(true);
      const article = FormState.values;
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };

      if (type === "edit") {
        axios
          //.put(`${GetApiLink()}/api/soedit/${id}`, article, { headers })
          .put(`${GetApiLink()}api/termsmaster/edit`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Terms Condition Master Update Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "true") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal({
                icon: "success",
                title: "Update",
                text: response.data.message,
                type: "success",
              }).then(function () {
                history.push(`${process.env.PUBLIC_URL}/view/Master/TermsConditionMaster/view/${FormState.values.id}`);
              });
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      } else if (type === "add") {
        axios
          .post(`${GetApiLink()}api/termsmaster/add`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Terms Condition Master Update Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "false") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal("Saved successfully.", "", "success");
              history.push(`${process.env.PUBLIC_URL}/view/Master/TermsConditionMaster/add/0`);
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      }
      // }
    }
  };
  const handleDeleteEntry = () => {
    setPdfLoading(true);
    const article = FormState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    axios
      .post(`${GetApiLink()}api/termsmaster/delete`, article, { headers })
      .then((response) => {
        setPdfLoading(false);
        console.log("Terms Condition Delete request :", response);
        if (response.data.validate === "false") {
          swal(response.data.message);
          return;
        } else if (response.data.error === "true") {
          swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
        } else {
          swal({
            icon: "success",
            title: "Update",
            text: response.data.message,
            type: "success",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Master/TermsConditionMasterList`);
          });
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Errors LOG", error);
      });
  };
  const handleDelete = (event) => {
    event.preventDefault();
    handleDeleteEntry(FormState.values.id);
  };
  const CheckDate = () => {
    setPdfLoading(false);
    if (FormState.values.mId === "" || typeof FormState.values.mId === "undefined" || FormState.values.mId === null) {
      swal("Please select valid material type");
      return false;
    } else if (
      FormState.values.tDesc === "" ||
      typeof FormState.values.tDesc === "undefined" ||
      FormState.values.tDesc === null
    ) {
      swal("Please select valid terms");
      return false;
    } else return true;
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };
  function handleEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    handleSaveEntry();
    if (type == "view") {
      history.push(`${process.env.PUBLIC_URL}/view/Master/TermsConditionMaster/edit/${FormState.values.id}`);
    }
  };
  const handleCancel = (event) => {
    event.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/view/Master/TermsConditionMasterList`);
  };
  useEffect(() => {
    viewChange();
    fetchMaterialList();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  useEffect(() => {
    if (type === "edit" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "view" && typeof id !== "undefined") {
      SoShow(id);
    }
  }, [type]);
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-md">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <OverlineTitle tag="span" className="preview-title-lg">
                {" "}
                Terms Condition Master
              </OverlineTitle>
              <form>
                <Row className="gy-4">
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        ID:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onKeyDown={handleEnter}
                          value={FormState.values.id || ""}
                          name={"id"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Material Test Name:
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.mId || ""}
                          name={"mId"}
                        >
                          <option value="0"> Select Material Name</option>
                          {MaterialList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.mName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Terms:
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          className="no-resize form-control"
                          type="textarea"
                          id="default-textarea"
                          disabled={type == "view" ? true : false}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          //onKeyDown={handleEnter}
                          value={FormState.values.tDesc || ""}
                          name="tDesc"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="gy-4">
                  <Col sm="2">
                    <Button
                      color="success"
                      name="Save"
                      style={{ display: type == "view" ? "none" : "" }}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      color="success"
                      name="Save"
                      style={{ display: type == "view" ? "" : "none" }}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Modify
                    </Button>
                  </Col>
                  <Col sm="2">
                    <Button
                      color="danger"
                      name="Delete"
                      onClick={(e) => {
                        handleDelete(e);
                      }}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col sm="2">
                    <Button
                      color="primary"
                      name="Cancel"
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </form>

              <Backdrop className={classes.backdrop} open={PdfLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default TermsConditionMaster;
