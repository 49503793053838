import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import classnames from "classnames";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  Button,
} from "../../components/Component";

import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import ReactInputDateMask from "react-input-date-mask";
import { GetApiLink, getRequestConfig, getUser } from "../../utils/Utils";
import swal from "sweetalert";
import axios from "axios";
import InputMask from "react-input-mask";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));
const JobInward = (props) => {
  const { match, history } = props;
  const { id, type } = match.params;
  const classes = useStyles();
  const [PdfLoading, setPdfLoading] = useState(false);
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [profileName, setProfileName] = useState("Abu Bin Ishtiak");
  const [activeTab, setActiveTab] = useState("1");
  const [modalMaterialForm, setMaterialModalForm] = useState(false);
  const [modalParameterForm, setParameterModalForm] = useState(false);
  const [modalForm, setModalForm] = useState(false);
  const [modalAgencyForm, setAgencyModalForm] = useState(false);
  const [modalConsultantForm, setConsultantModalForm] = useState(false);
  const [modelContractorForm, setContractorModalForm] = useState(false);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleMaterialForm = () => setMaterialModalForm(!modalMaterialForm);
  const toggleParameterForm = () => setParameterModalForm(!modalParameterForm);
  const toggleForm = () => setModalForm(!modalForm);
  const toggleAgencyForm = () => setAgencyModalForm(!modalAgencyForm);
  const toggleConsltantForm = () => setConsultantModalForm(!modalConsultantForm);
  const toggleSubContractorForm = () => setContractorModalForm(!modelContractorForm);

  // function to change the design view under 990 px
  const [FormState, setFormState] = useState({
    values: {
      materialArray: [],
      paraArray: [],
    },
  });
  const [FormCustState, setFormCustState] = useState({
    values: {},
  });
  const [FormAgencyState, setFormAgencyState] = useState({
    values: {},
  });
  const [FormConsState, setFormConsState] = useState({
    values: {},
  });
  const [FormContractorState, setFormContractorState] = useState({
    values: {},
  });
  const [customerList, setCustomerList] = useState([]);
  const [consultantList, setConsultantMasterList] = useState([]);
  const [agencyList, setAgenctyMasterList] = useState([]);
  const [subcontractorList, setSubcontractorList] = useState([]);
  const [UnitMasterList, setUnitMasterList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [PaymentList, setPaymentList] = useState([]);
  const [TestGroupList, setTestGroupList] = useState([]);
  const [MaterialList, setMaterialist] = useState([]);
  const [TestMasterList, setTestMasterist] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [valid, setValid] = useState(false);
  const [date, setDate] = useState();
  const [materialInfo, setMaterialInfo] = useState([]);
  const [parameterInfo, setParameterInfo] = useState([]);
  const [getTempMaterialData, setTempMaterialData] = useState([]);
  const [getTempParaData, setTempParaData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [getUncheckedMaterial, setUncheckedMaterial] = useState("");
  const [getParameterUnchecked, setParameterUnchecked] = useState(0);
  let keysPressed = {};
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  const SoShow = (testId) => {
    setPdfLoading(true);
    // if (id === "") {
    //   //setPdfLoading(false);
    //   history.push(`${process.env.PUBLIC_URL}/view/Inward/SampleInwardList`);
    // }
    axios
      .get(
        `${GetApiLink()}api/jobinward/${testId}`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Job Inward Data Show Get: ", response);
        //console.log('A',response.data.response.error);
        if (typeof response.data.error != "undefined" && response.data.error == "true") {
          swal({
            icon: "error",
            title: "Opps!!!",
            text: response.data.message,
            type: "error",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Inward/JobInwardList`);
          });
        } else {
          // if (response.data.response.isauth == "_AUTHFAILED") {
          //   history.push("/auth/logout");
          // } else {
          if (type === "add") {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ...response.data.data,
              },
            }));
          } else {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ...response.data.data,
                jobDate: moment(response.data.data.jobDate).format("DD-MM-YYYY"),
                refDate: moment(response.data.data.refDate).format("DD-MM-YYYY"),
                ...response.data.data_detail[0],
                materialArray: response.data.d1,
                paraArray: response.data.d2,
              },
            }));
            //setTestMasterist(response.data.d2);
            const SavedMaterialList =
              response.data.d1 &&
              response.data.d1.filter((el) => {
                if (el.id != null) {
                  el.id = el.mId;
                  el.isChecked = true;
                }
                return el;
              });
            const SavedParaList =
              response.data.d2 &&
              response.data.d2.filter((el) => {
                if (el.id != null) {
                  el.id = el.tId;
                  el.isChecked = true;
                }
                return el;
              });
            console.log("Saved ParaArray ", SavedParaList);
            setMaterialInfo(SavedMaterialList);
            setParameterInfo(SavedParaList);
            fetchMaterialList(response.data.data_detail[0].tGrpId);
            fetchTestMasterList(response.data.data_detail[0].mId);
            setTempParaData(SavedParaList);
            setTempMaterialData(response.data.d1);
            if (response.data.data.deliveryType == "customer") {
              getCustomerDetail(response.data.data.custId);
            } else if (response.data.data.deliveryType == "agency") {
              getAgencyDetail(response.data.data.agencyId);
            } else if (response.data.data.deliveryType == "subcontractor") {
              getSubContractorDetail(response.data.data.subContractId);
            }
            console.log("Viral Array ", response.data.d1);
          }
          //}
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Job Inward Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  };
  function fetchCustomerMasterList() {
    axios
      .get(`${GetApiLink()}api/customermasterlist/CUS`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Customer Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setCustomerList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Customer Master List Error: ", error);
      });
  }
  function fetchConsultantMasterList() {
    axios
      .get(`${GetApiLink()}api/customermasterlist/CON`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Consultant Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setConsultantMasterList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Consultant Master List Error: ", error);
      });
  }
  function fetchAgencyMasterList() {
    axios
      .get(`${GetApiLink()}api/customermasterlist/AGN`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Consultant Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setAgenctyMasterList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Consultant Master List Error: ", error);
      });
  }
  function fetchSubContractorList() {
    axios
      .get(`${GetApiLink()}api/customermasterlist/SCN`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" SubContractor Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setSubcontractorList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" SubContractor Master List Error: ", error);
      });
  }
  function fetchUnitMasterList() {
    axios
      .get(`${GetApiLink()}api/unitmasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Unit Master Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setUnitMasterList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Unit Master Error: ", error);
      });
  }
  function fetchMaterialList(tGrpId) {
    axios
      .get(`${GetApiLink()}api/materiallist/${tGrpId}`)
      .then((response) => {
        console.log(" Material Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setMaterialist(response.data);
        //fetchTestMasterList(response.data[0].id);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Material Master List Get Error: ", error);
      });
  }
  function fetchTestGroupList() {
    axios
      .get(`${GetApiLink()}api/testgroups`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Test Group List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setTestGroupList(response.data);
        //fetchMaterialList(response.data[0].id);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Test Group List Get Error: ", error);
      });
  }
  function fetchTestMasterList(mId) {
    axios
      .get(`${GetApiLink()}api/parameterlist/${mId}`)
      .then((response) => {
        console.log(" Test Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setTestMasterist(response.data.data);
        //toggleParameterForm();
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Test Master List Get Error: ", error);
      });
  }
  function fetchCityMasterList() {
    axios
      .get(`${GetApiLink()}api/citymasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" City List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setCityList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" City List Get Error: ", error);
      });
  }
  function fetchStateMasterList() {
    axios
      .get(`${GetApiLink()}api/statemasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" State List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setStateList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" State List Get Error: ", error);
      });
  }
  function fetchCountryList() {
    axios
      .get(`${GetApiLink()}api/countrymasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Country List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setCountryList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Country List Get Error: ", error);
      });
  }
  function fetchPaymentList() {
    axios
      .get(`${GetApiLink()}api/paymentmasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Payment List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setPaymentList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Payment List Get Error: ", error);
      });
  }
  function getCustMaxId() {
    //setPdfLoading(true);
    axios
      .get(
        `${GetApiLink()}api/customermaster/0`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        //setPdfLoading(false);
        console.log(" Customer master Data Show Get: ", response);
        //console.log('A',response.data.response.error);
        if (typeof response.data.error != "undefined" && response.data.error == "true") {
          swal(response.data.message);
        } else {
          // if (response.data.response.isauth == "_AUTHFAILED") {
          //   history.push("/auth/logout");
          // } else {
          if (type === "add") {
            setFormCustState((FormCustState) => ({
              ...FormCustState,
              values: {
                ...FormCustState.values,
                id: response.data.maxId,
              },
            }));
            setFormAgencyState((FormAgencyState) => ({
              ...FormAgencyState,
              values: {
                ...FormAgencyState.values,
                id: response.data.maxId,
              },
            }));
            setFormConsState((FormConsState) => ({
              ...FormConsState,
              values: {
                ...FormConsState.values,
                id: response.data.maxId,
              },
            }));
          }
          //}
        }
      })
      .catch((error) => {
        //setPdfLoading(false);
        console.log("Customer Master Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  }
  function getCustomerDetail(custId) {
    axios
      .get(
        `${GetApiLink()}api/customermaster/${custId}`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        console.log(" Customer master Data Show Get: ", response);
        setFormState((FormState) => ({
          ...FormState,
          values: {
            ...FormState.values,
            ["address"]: response.data.data.cAddressOne,
            ["zipcode"]: response.data.data.cPincode == null ? "" : response.data.data.cPincode,
            ["contactNo"]: response.data.data.cNumberOne,
          },
        }));
      })
      .catch((error) => {
        console.log("Customer Master Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  }
  function getAgencyDetail(agencyId) {
    axios
      .get(
        `${GetApiLink()}api/customermaster/${agencyId}`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        console.log("Agency master Data Show Get: ", response);
        setFormState((FormState) => ({
          ...FormState,
          values: {
            ...FormState.values,
            ["address"]: response.data.data.cAddressOne,
            ["zipcode"]: response.data.data.cPincode == null ? "" : response.data.data.cPincode,
            ["contactNo"]: response.data.data.cNumberOne,
          },
        }));
      })
      .catch((error) => {
        console.log("Customer Master Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  }
  function getSubContractorDetail(subcontraId) {
    axios
      .get(
        `${GetApiLink()}api/customermaster/${subcontraId}`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        console.log(" SubContractor master Data Show Get: ", response);
        setFormState((FormState) => ({
          ...FormState,
          values: {
            ...FormState.values,
            ["address"]: response.data.data.cAddressOne,
            ["zipcode"]: response.data.data.cPincode == null ? "" : response.data.data.cPincode,
            ["contactNo"]: response.data.data.cNumberOne,
          },
        }));
      })
      .catch((error) => {
        console.log("SubContractor Master Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  }
  const handleFocus = (event) => event.target.select();
  const handleChange = (event) => {
    event.persist();
    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        [event.target.name]: event.target.value,
      },
    }));
    if (event.target.name == "tGrpId") {
      //toggleMaterialForm()
      fetchMaterialList(event.target.value);
    }
    if (event.target.name == "mId") {
      fetchTestMasterList(event.target.value);
      if (event.target.value != FormState.values.mId) {
        setParameterInfo([]);
      }
    }
    // if (event.target.name == "tId") {
    //   setFormState((FormState) => ({
    //     ...FormState,
    //     values: {
    //       ...FormState.values,
    //       ["tId"]: Array.from(event.target.selectedOptions, (item) => item.value),
    //     },
    //   }));
    // }
    if (event.target.name == "custId") {
      getCustomerDetail(event.target.value);
    }
    if (event.target.name == "deliveryType") {
      if (event.target.value == "customer") {
        getCustomerDetail(FormState.values.custId);
      } else if (event.target.value == "agency") {
        getAgencyDetail(FormState.values.agencyId);
      } else if (event.target.value == "subcontractor") {
        getSubContractorDetail(FormState.values.subContractId);
      }
    }
    console.log("FormState ", FormState);
  };
  function handleEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
        if (event.target.name === "jobDate") {
          setDate(event.target.value);
          if (event.target.value !== "") {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ["jobDate"]: event.target.value,
              },
            }));
          }
        }
      }
    }
  }
  function handlmReceiveeEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  const handleCustChange = (event) => {
    event.persist();
    setFormCustState((FormCustState) => ({
      ...FormCustState,
      values: {
        ...FormCustState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };
  function handleCustEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  const handleAgencyChange = (event) => {
    event.persist();
    setFormAgencyState((FormAgencyState) => ({
      ...FormAgencyState,
      values: {
        ...FormAgencyState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };
  function handleAgencyEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  const handleConsChange = (event) => {
    event.persist();
    setFormConsState((FormConsState) => ({
      ...FormConsState,
      values: {
        ...FormConsState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };
  function handleConsEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  const handleContractorChange = (event) => {
    event.persist();
    setFormContractorState((FormContractorState) => ({
      ...FormContractorState,
      values: {
        ...FormContractorState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };
  function handleContractorEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  const handleCustomerSaveEntry = () => {
    setPdfLoading(true);
    if (CheckCustData()) {
      setPdfLoading(true);
      const article = FormCustState.values;
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };

      axios
        .post(`${GetApiLink()}api/customermaster/add`, article, { headers })
        .then((response) => {
          setPdfLoading(false);
          console.log("Customer Save request :", response);
          if (response.data.validate === "false") {
            swal(response.data.message);
            return;
          } else if (response.data.error === "false") {
            swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
          } else {
            fetchCustomerMasterList();
            setFormCustState((FormCustState) => ({
              ...FormCustState,
              values: {},
            }));
            toggleForm();
          }
        })
        .catch((error) => {
          setPdfLoading(false);
          console.log("Errors LOG", error);
        });

      // }
    }
  };
  function fetchEmployeeMasterList() {
    axios
      .get(`${GetApiLink()}api/employeemasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Employee Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setEmployeeList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Employee Master List Error: ", error);
      });
  }
  const handleAgencySaveEntry = () => {
    setPdfLoading(true);
    if (CheckAgencyData()) {
      setPdfLoading(true);
      const article = FormAgencyState.values;
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };

      axios
        .post(`${GetApiLink()}api/customermaster/add`, article, { headers })
        .then((response) => {
          setPdfLoading(false);
          console.log("Agency Save request :", response);
          if (response.data.validate === "false") {
            swal(response.data.message);
            return;
          } else if (response.data.error === "false") {
            swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
          } else {
            fetchAgencyMasterList();

            setFormAgencyState((FormAgencyState) => ({
              ...FormAgencyState,
              values: {},
            }));
            toggleAgencyForm();
          }
        })
        .catch((error) => {
          setPdfLoading(false);
          console.log("Errors LOG", error);
        });

      // }
    }
  };
  const handleConsSaveEntry = () => {
    setPdfLoading(true);
    if (CheckConsData()) {
      setPdfLoading(true);
      const article = FormConsState.values;
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };

      axios
        .post(`${GetApiLink()}api/customermaster/add`, article, { headers })
        .then((response) => {
          setPdfLoading(false);
          console.log("Consultancy Save request :", response);
          if (response.data.validate === "false") {
            swal(response.data.message);
            return;
          } else if (response.data.error === "false") {
            swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
          } else {
            fetchConsultantMasterList();
            setFormConsState((FormConsState) => ({
              ...FormConsState,
              values: {},
            }));
            toggleConsltantForm();
          }
        })
        .catch((error) => {
          setPdfLoading(false);
          console.log("Errors LOG", error);
        });

      // }
    }
  };
  const handleSubContractorSave = () => {
    setPdfLoading(true);
    if (CheckContractorData()) {
      setPdfLoading(true);
      const article = FormContractorState.values;
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };

      axios
        .post(`${GetApiLink()}api/customermaster/add`, article, { headers })
        .then((response) => {
          setPdfLoading(false);
          console.log("Contractor Save request :", response);
          if (response.data.validate === "false") {
            swal(response.data.message);
            return;
          } else if (response.data.error === "false") {
            swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
          } else {
            fetchSubContractorList();
            setFormContractorState((FormContractorState) => ({
              ...FormContractorState,
              values: {},
            }));
            toggleSubContractorForm();
          }
        })
        .catch((error) => {
          setPdfLoading(false);
          console.log("Errors LOG", error);
        });

      // }
    }
  };
  const CheckCustData = () => {
    setPdfLoading(false);
    if (
      FormCustState.values.cCode === "" ||
      typeof FormCustState.values.cCode === "undefined" ||
      FormCustState.values.cCode === null
    ) {
      swal("Please select valid code");
      return false;
    } else if (
      FormCustState.values.cName === "" ||
      typeof FormCustState.values.cName === "undefined" ||
      FormCustState.values.cName === null
    ) {
      swal("Please select valid Name");
      return false;
    } else if (
      FormCustState.values.cType === "" ||
      typeof FormCustState.values.cType === "undefined" ||
      FormCustState.values.cType === null ||
      FormCustState.values.cType === "0"
    ) {
      swal("Please select valid Customer type");
      return false;
    } else if (
      FormCustState.values.cNumberOne === "" ||
      typeof FormCustState.values.cNumberOne === "undefined" ||
      FormCustState.values.cNumberOne === null
    ) {
      swal("Please select valid Contact Number");
      return false;
    } else if (
      FormCustState.values.cAddressOne === "" ||
      typeof FormCustState.values.cAddressOne === "undefined" ||
      FormCustState.values.cAddressOne === null
    ) {
      swal("Please select valid Address");
      return false;
    } else if (
      FormCustState.values.cPanCard === "" ||
      typeof FormCustState.values.cPanCard === "undefined" ||
      FormCustState.values.cPanCard === null
    ) {
      swal("Please select valid Pancard");
      return false;
    } else if (
      FormCustState.values.cGst === "" ||
      typeof FormCustState.values.cGst === "undefined" ||
      FormCustState.values.cGst === null
    ) {
      swal("Please select valid GST No");
      return false;
    } else if (
      FormCustState.values.cCategory === "" ||
      typeof FormCustState.values.cCategory === "undefined" ||
      FormCustState.values.cCategory === null ||
      FormCustState.values.cCategory === "0"
    ) {
      swal("Please select valid Category");
      return false;
    } else if (
      FormCustState.values.pId === "" ||
      typeof FormCustState.values.pId === "undefined" ||
      FormCustState.values.pId === null ||
      FormCustState.values.pId === "0"
    ) {
      swal("Please select valid Payment Type");
      return false;
    } else return true;
  };
  const CheckAgencyData = () => {
    setPdfLoading(false);
    if (
      FormAgencyState.values.cCode === "" ||
      typeof FormAgencyState.values.cCode === "undefined" ||
      FormAgencyState.values.cCode === null
    ) {
      swal("Please select valid code");
      return false;
    } else if (
      FormAgencyState.values.cName === "" ||
      typeof FormAgencyState.values.cName === "undefined" ||
      FormAgencyState.values.cName === null
    ) {
      swal("Please select valid Name");
      return false;
    } else if (
      FormAgencyState.values.cType === "" ||
      typeof FormAgencyState.values.cType === "undefined" ||
      FormAgencyState.values.cType === null ||
      FormAgencyState.values.cType === "0"
    ) {
      swal("Please select valid Customer type");
      return false;
    } else if (
      FormAgencyState.values.cNumberOne === "" ||
      typeof FormAgencyState.values.cNumberOne === "undefined" ||
      FormAgencyState.values.cNumberOne === null
    ) {
      swal("Please select valid Contact Number");
      return false;
    } else if (
      FormAgencyState.values.cAddressOne === "" ||
      typeof FormAgencyState.values.cAddressOne === "undefined" ||
      FormAgencyState.values.cAddressOne === null
    ) {
      swal("Please select valid Address");
      return false;
    } else if (
      FormAgencyState.values.cPanCard === "" ||
      typeof FormAgencyState.values.cPanCard === "undefined" ||
      FormAgencyState.values.cPanCard === null
    ) {
      swal("Please select valid Pancard");
      return false;
    } else if (
      FormAgencyState.values.cGst === "" ||
      typeof FormAgencyState.values.cGst === "undefined" ||
      FormAgencyState.values.cGst === null
    ) {
      swal("Please select valid GST No");
      return false;
    } else if (
      FormAgencyState.values.cCategory === "" ||
      typeof FormAgencyState.values.cCategory === "undefined" ||
      FormAgencyState.values.cCategory === null ||
      FormAgencyState.values.cCategory === "0"
    ) {
      swal("Please select valid Category");
      return false;
    } else if (
      FormAgencyState.values.pId === "" ||
      typeof FormAgencyState.values.pId === "undefined" ||
      FormAgencyState.values.pId === null ||
      FormAgencyState.values.pId === "0"
    ) {
      swal("Please select valid Payment Type");
      return false;
    } else return true;
  };
  const CheckConsData = () => {
    setPdfLoading(false);
    if (
      FormConsState.values.cCode === "" ||
      typeof FormConsState.values.cCode === "undefined" ||
      FormConsState.values.cCode === null
    ) {
      swal("Please select valid code");
      return false;
    } else if (
      FormConsState.values.cName === "" ||
      typeof FormConsState.values.cName === "undefined" ||
      FormConsState.values.cName === null
    ) {
      swal("Please select valid Name");
      return false;
    } else if (
      FormConsState.values.cType === "" ||
      typeof FormConsState.values.cType === "undefined" ||
      FormConsState.values.cType === null ||
      FormConsState.values.cType === "0"
    ) {
      swal("Please select valid Customer type");
      return false;
    } else if (
      FormConsState.values.cNumberOne === "" ||
      typeof FormConsState.values.cNumberOne === "undefined" ||
      FormConsState.values.cNumberOne === null
    ) {
      swal("Please select valid Contact Number");
      return false;
    } else if (
      FormConsState.values.cAddressOne === "" ||
      typeof FormConsState.values.cAddressOne === "undefined" ||
      FormConsState.values.cAddressOne === null
    ) {
      swal("Please select valid Address");
      return false;
    } else if (
      FormConsState.values.cPanCard === "" ||
      typeof FormConsState.values.cPanCard === "undefined" ||
      FormConsState.values.cPanCard === null
    ) {
      swal("Please select valid Pancard");
      return false;
    } else if (
      FormConsState.values.cGst === "" ||
      typeof FormConsState.values.cGst === "undefined" ||
      FormConsState.values.cGst === null
    ) {
      swal("Please select valid GST No");
      return false;
    } else if (
      FormConsState.values.cCategory === "" ||
      typeof FormConsState.values.cCategory === "undefined" ||
      FormConsState.values.cCategory === null ||
      FormConsState.values.cCategory === "0"
    ) {
      swal("Please select valid Category");
      return false;
    } else if (
      FormConsState.values.pId === "" ||
      typeof FormConsState.values.pId === "undefined" ||
      FormConsState.values.pId === null ||
      FormConsState.values.pId === "0"
    ) {
      swal("Please select valid Payment Type");
      return false;
    } else return true;
  };
  const CheckContractorData = () => {
    setPdfLoading(false);
    if (
      FormContractorState.values.cCode === "" ||
      typeof FormContractorState.values.cCode === "undefined" ||
      FormContractorState.values.cCode === null
    ) {
      swal("Please select valid code");
      return false;
    } else if (
      FormContractorState.values.cName === "" ||
      typeof FormContractorState.values.cName === "undefined" ||
      FormContractorState.values.cName === null
    ) {
      swal("Please select valid Name");
      return false;
    } else if (
      FormContractorState.values.cType === "" ||
      typeof FormContractorState.values.cType === "undefined" ||
      FormContractorState.values.cType === null ||
      FormContractorState.values.cType === "0"
    ) {
      swal("Please select valid Customer type");
      return false;
    } else if (
      FormContractorState.values.cNumberOne === "" ||
      typeof FormContractorState.values.cNumberOne === "undefined" ||
      FormContractorState.values.cNumberOne === null
    ) {
      swal("Please select valid Contact Number");
      return false;
    } else if (
      FormContractorState.values.cAddressOne === "" ||
      typeof FormContractorState.values.cAddressOne === "undefined" ||
      FormContractorState.values.cAddressOne === null
    ) {
      swal("Please select valid Address");
      return false;
    } else if (
      FormContractorState.values.cPanCard === "" ||
      typeof FormContractorState.values.cPanCard === "undefined" ||
      FormContractorState.values.cPanCard === null
    ) {
      swal("Please select valid Pancard");
      return false;
    } else if (
      FormContractorState.values.cGst === "" ||
      typeof FormContractorState.values.cGst === "undefined" ||
      FormContractorState.values.cGst === null
    ) {
      swal("Please select valid GST No");
      return false;
    } else if (
      FormContractorState.values.cCategory === "" ||
      typeof FormContractorState.values.cCategory === "undefined" ||
      FormContractorState.values.cCategory === null ||
      FormContractorState.values.cCategory === "0"
    ) {
      swal("Please select valid Category");
      return false;
    } else if (
      FormContractorState.values.pId === "" ||
      typeof FormContractorState.values.pId === "undefined" ||
      FormContractorState.values.pId === null ||
      FormContractorState.values.pId === "0"
    ) {
      swal("Please select valid Payment Type");
      return false;
    } else return true;
  };
  const finalMaterialArray = materialInfo.filter((el) => {
    return el.isChecked == true;
  });
  const finalParaArray = parameterInfo.filter((el) => {
    return el.isChecked == true;
  });
  const handleSaveEntry = () => {
    setPdfLoading(true);

    FormState.values.materialArray = finalMaterialArray;
    FormState.values.paraArray = finalParaArray;
    // setFormState((FormState) => ({
    //   ...FormState,
    //   values: {
    //     ...FormState.values,
    //     paraArray:finalParaArray
    //   }
    // })
    // )
    console.log("Paramater Array ", finalParaArray, FormState.values.paraArray);
    if (CheckData()) {
      setPdfLoading(true);
      const article = FormState.values;
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };

      if (type === "edit") {
        axios
          //.put(`${GetApiLink()}/api/soedit/${id}`, article, { headers })
          .put(`${GetApiLink()}api/jobinward/edit`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Job Inward Update Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "false") {
              swal("Error", `Opps Something went wrong!!!`, "error");
            } else {
              swal({
                icon: "success",
                title: "Update",
                text: response.data.message,
                type: "success",
              }).then(function () {
                history.push(`${process.env.PUBLIC_URL}/view/Inward/JobInward/view/${FormState.values.id}`);
              });
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      } else if (type === "add") {
        axios
          .post(`${GetApiLink()}api/jobinward/add`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Job Inward Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "false") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal(response.data.message, "", "success");
              history.push(`${process.env.PUBLIC_URL}/view/Inward/JobInwardList`);
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      }
    }
    //}
  };
  const handleGetInwardNumber = () => {
    const article = FormState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    axios
      .post(`${GetApiLink()}api/jobinward/getInwNumber`, article, { headers })
      .then((response) => {
        console.log("Inward Number request :", response);
        setFormState((FormState) => ({
          ...FormState,
          values: {
            ...FormState.values,
            ["jobNo"]: response.data.jobNo,
            ["labNumber"]: response.data.labNumber,
            ["currentDay"]: response.data.currentDay,
            ["currentMonth"]: response.data.currentMonth,
            ["currentYear"]: response.data.currentYear,
            ["SrNo"]: response.data.SrNo,
          },
        }));
      })
      .catch((error) => {
        console.log("Errors LOG", error);
      });

    // }
  };

  useEffect(() => {
    if (type === "edit" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "view" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "add") {
      SoShow("0");
    }
    fetchCustomerMasterList();
    fetchAgencyMasterList();
    fetchConsultantMasterList();
    fetchSubContractorList();
    fetchTestGroupList();
    fetchUnitMasterList();
    fetchCityMasterList();
    fetchCountryList();
    fetchStateMasterList();
    fetchPaymentList();
    getCustMaxId();
    fetchEmployeeMasterList();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  useEffect(() => {
    const regexddmmyyyy = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;

    if (regexddmmyyyy.test(date)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [date]);
  useEffect(() => {
    if (valid == false) {
      //swal('Please enter valid date format');
    }
  }, [valid]);
  useEffect(() => {
    if (getParameterUnchecked == 1) {
      const newUnCheckedParaList = parameterInfo.filter((el) => {
        if (el.mId == getUncheckedMaterial) {
          el.isChecked = false;
        }
      });
      setTestMasterist(newUnCheckedParaList);
      setParameterUnchecked(0);
      setUncheckedMaterial("");
      console.log("Unchecked ", getUncheckedMaterial, newUnCheckedParaList, parameterInfo);
    }
  }, [getParameterUnchecked]);
  function currentDate() {
    var today = new Date(),
      date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
    return date;
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    handleSaveEntry();
    if (type == "view") {
      history.push(`${process.env.PUBLIC_URL}/view/Inward/JobInward/edit/${FormState.values.id}`);
    }
  };
  const handleDelete = (event) => {
    event.preventDefault();
  };
  const handleCancel = (event) => {
    event.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/view/Inward/JobInwardList`);
  };
  const handleCustSave = (event) => {
    event.preventDefault();
    handleCustomerSaveEntry();
  };
  // const CheckD1Data = FormState.values.materialArray.filter(el =>{
  //   return el.isChecked == true;
  // })
  // const CheckD2Data = parameterInfo.filter(el =>{
  //   return el.isChecked == true;
  // })
  const CheckData = () => {
    setPdfLoading(false);

    if (!FormState.values.jobDate) {
      swal("Please enter valid job date");
      return false;
    } else if (!FormState.values.jobNo) {
      swal("Please enter valid job inward no");
      return false;
    } else if (!FormState.values.refNo) {
      swal("Please enter valid Ref. No");
      return false;
    } else if (!FormState.values.refDate) {
      swal("Please enter valid Ref. date");
      return false;
    } else if (!FormState.values.custId) {
      swal("Please select valid Customer");
      return false;
    } else if (!FormState.values.agencyId) {
      swal("Please select valid Agency");
      return false;
    } else if (!FormState.values.consId) {
      swal("Please select valid Consultant");
      return false;
    } else if (!FormState.values.subContractId) {
      swal("Please select valid Sub Contractor");
      return false;
    } else if (!FormState.values.deliveryType) {
      swal("Please select valid Invoice Delivery Option");
      return false;
    } else if (!FormState.values.work_name) {
      swal("Please enter Name of work");
      return false;
    } else if (!FormState.values.testWitness) {
      swal("Please selevt valid Test Witness");
      return false;
    } else if (FormState.values.testWitness == "Yes" && !FormState.values.uRep) {
      swal("Please enter valid Reprensentative");
      return false;
    } else if (FormState.values.testWitness == "Yes" && !FormState.values.uRepMob) {
      swal("Please enter valid Reprensentative Contact No");
      return false;
    } else if (!FormState.values.tGrpId) {
      swal("Please select valid Test Group");
      return false;
    } else if (FormState.values.materialArray.length == 0) {
      swal("Please valid Material Type");
      return false;
      // }else if(!FormState.values.tId){
      //   swal('Please valid Test Type')
      //   return false;
    } else if (!FormState.values.gradeId) {
      swal("Please enter valid Grade");
      return false;
      // }else if(!FormState.values.testSpec){
      //   swal('Please valid Test Specification')
      //   return false;
    } else if (!FormState.values.batchNo) {
      swal("Please enter valid Batch No");
      return false;
    } else if (!FormState.values.totalSample) {
      swal("Please enter valid Total No. Of Sample");
      return false;
    } else if (!FormState.values.suom) {
      swal("Please select valid Total No of Sample UOM");
      return false;
      // }else if(!FormState.values.qty){
      //   swal('Please valid Qty')
      //   return false;
      // }else if(!FormState.values.quom){
      //   swal('Please valid Qty UOM')
      //   return false;
    } else if (!FormState.values.totalSet) {
      swal("Please enter valid Total Sets");
      return false;
    } else if (!FormState.values.cSpec) {
      swal("Please enter valid Customer Specification");
      return false;
      // }else if(!FormState.values.testerId){
      //   swal('Please valid Tester')
      //   return false;
      // }else if(!FormState.values.expTotalSample){
      //   swal('Please valid Expected Total Sample')
      //   return false;
      // }else if(!FormState.values.approvId){
      //   swal('Please valid Approver')
      //   return false;
    } else if (!FormState.values.sampleCondition) {
      swal("Please enter valid Sample Condition");
      return false;
      // }else if(!FormState.values.sysDate){
      //   swal('Please valid Testing Date By System')
      //   return false;
      // }else if(!FormState.values.mId){
      //   swal('Please Select Material Type')
      //   return false;
    } else if (FormState.values.paraArray.length == 0) {
      swal("Please Select Parameter");
      return false;
    } else if (!FormState.values.rptDate) {
      swal("Please select valid Report date");
      return false;
    } else {
      return true;
    }
  };
  const loadParameterList = (event, paraid) => {
    event.preventDefault();
    fetchTestMasterList(paraid);
  };
  const [isNewChecked, setIsNewChecked] = useState(false);
  const createMaterialArray = (isChecked, viral) => {
    console.log("Cehckbox ", isChecked, viral);
    let flag = 0;
    const viralArray =
      MaterialList &&
      MaterialList.filter((el) => {
        return el.id == viral;
      });
    viralArray &&
      viralArray.map((d) => {
        if (isChecked == true) {
          setMaterialInfo([
            ...materialInfo,
            {
              id: viral,
              mId: viral,
              tGrpId: d.tGrpId,
              mName: d.mName,
              isChecked: isChecked,
            },
          ]);
          const StatusUpdate =
            MaterialList &&
            MaterialList.filter((el) => {
              if (el.id == viral) {
                el.isChecked = isChecked;
              }
              return el;
            });
          console.log(StatusUpdate);
          setMaterialist(StatusUpdate);
          fetchTestMasterList(viral);
        } else {
          const newArray = materialInfo.filter((e) => {
            if (e.id == viral) {
              e.isChecked = isChecked;
            }
            return e;
          });
          const StatusUpdate =
            MaterialList &&
            MaterialList.filter((el) => {
              if (el.id == viral) {
                el.isChecked = isChecked;
              }
              return el;
            });
          setMaterialist(StatusUpdate);
          setMaterialInfo(newArray);
          setParameterUnchecked(1);
          setUncheckedMaterial(viral);
        }
      });
  };
  const createParameterArray = (isChecked, viral) => {
    console.log("Cehckbox ", isChecked, viral);
    let flag = 0;
    const viralArray =
      TestMasterList &&
      TestMasterList.filter((el) => {
        return el.id == viral;
      });
    viralArray &&
      viralArray.map((d) => {
        if (isChecked == true) {
          setParameterInfo([
            ...parameterInfo,
            {
              id: viral,
              tId: viral,
              tGrpId: d.tGrpId,
              mId: d.mId,
              pName: d.pName,
              isChecked: isChecked,
            },
          ]);
          const StatusUpdate =
            TestMasterList &&
            TestMasterList.filter((el) => {
              if (el.id == viral) {
                el.isChecked = isChecked;
              }
              return el;
            });
          console.log(StatusUpdate);
          setTestMasterist(StatusUpdate);
        } else {
          const newArray = parameterInfo.filter((e) => {
            if (e.id == viral) {
              e.isChecked = isChecked;
            }
            return e;
          });
          console.log("False Array", newArray, parameterInfo);
          const StatusUpdate =
            TestMasterList &&
            TestMasterList.filter((el) => {
              if (el.id == viral) {
                el.isChecked = isChecked;
              }
              return el;
            });
          setTestMasterist(StatusUpdate);
          setParameterInfo(newArray);
        }
      });
  };
  const handleMaterialSave = () => {
    const finalMaterialArray = materialInfo.filter((el) => {
      return el.isChecked == true;
    });
    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        materialArray: finalMaterialArray,
      },
    }));
    toggleMaterialForm();
  };
  const handleParaSave = () => {
    const finalParaArray = parameterInfo.filter((el) => {
      return el.isChecked == true;
    });
    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        paraArray: finalParaArray,
      },
    }));

    toggleParameterForm();
  };
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <OverlineTitle tag="span" className="preview-title-lg">
                {" "}
                Job Inward Form
              </OverlineTitle>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle("1");
                    }}
                  >
                    Test Request Detail
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle("2");
                    }}
                  >
                    Job Inward Form
                  </NavLink>
                </NavItem>
              </Nav>
              <form>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row className="gy-4">
                      <Col sm="2">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Form No :
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              autoFocus
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              name="formNo"
                              value={FormState.values.formNo}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Job Inward Date *:
                          </Label>
                          <div className="form-control-wrap">
                            <label onKeyDown={handleEnter}>
                              <input
                                className="form-control form-control-sm"
                                type="hidden"
                                disabled
                                id="default-0"
                                placeholder=""
                                name="id"
                                value={FormState.values.id}
                              />
                              <InputMask
                                mask="99-99-9999"
                                tabIndex={0}
                                className="form-control form-control-sm"
                                name="jobDate"
                                style={{ width: "100%" }}
                                disabled={type === "view" ? true : false}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onFocus={handleFocus}
                                value={
                                  !FormState.values.jobDate
                                    ? (FormState.values.jobDate = moment(new Date()).format("DD-MM-YYYY"))
                                    : FormState.values.jobDate
                                }
                                onBlur={(e) => {
                                  handleGetInwardNumber(e);
                                }}
                              />
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Job Inward No *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              disabled
                              id="default-0"
                              placeholder=""
                              name="jobNo"
                              value={FormState.values.jobNo}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            />
                            <input
                              className="form-control form-control-sm"
                              type="hidden"
                              disabled
                              id="default-0"
                              placeholder=""
                              name="labNumber"
                              value={FormState.values.labNumber}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            />
                            <input
                              className="form-control form-control-sm"
                              type="hidden"
                              disabled
                              id="default-0"
                              placeholder=""
                              name="currentDay"
                              value={FormState.values.currentDay}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            />
                            <input
                              className="form-control form-control-sm"
                              type="hidden"
                              disabled
                              id="default-0"
                              placeholder=""
                              name="currentMonth"
                              value={FormState.values.currentMonth}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            />
                            <input
                              className="form-control form-control-sm"
                              type="hidden"
                              disabled
                              id="default-0"
                              placeholder=""
                              name="currentYear"
                              value={FormState.values.currentYear}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="2">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Ref. / PO No *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-0"
                              placeholder=""
                              name="refNo"
                              value={FormState.values.refNo}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Ref / PO Date *:
                          </Label>
                          <div className="form-control-wrap">
                            <label onKeyDown={handleEnter}>
                              <InputMask
                                mask="99-99-9999"
                                tabIndex={0}
                                className="form-control form-control-sm"
                                name="refDate"
                                style={{ width: "100%" }}
                                disabled={type === "view" ? true : false}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onFocus={handleFocus}
                                value={
                                  !FormState.values.refDate
                                    ? (FormState.values.refDate = moment(new Date()).format("DD-MM-YYYY"))
                                    : FormState.values.refDate
                                }
                              />
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-customer" className="form-label">
                            Customer *:
                            <div style={{ width: "10%", height: "10%", float: "right", marginTop: "0px" }}>
                              <a
                                className="btn-round btn-icon"
                                color="success"
                                size="sm"
                                style={{ padding: "5px", background: "#1ee0ac" }}
                                onClick={toggleForm}
                              >
                                <Icon name="plus" />
                              </a>
                            </div>
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              style={{ width: "85%" }}
                              name="custId"
                              value={FormState.values.custId}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            >
                              <option value="0">Customer List</option>
                              {customerList.map((c) => {
                                return (
                                  <option value={c.id} key={c.id}>
                                    {c.cName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="default-rep" className="form-label">
                            Customer Representative:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-rep"
                              placeholder=""
                              name="custRep"
                              value={FormState.values.custRep}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="default-rep" className="form-label">
                            Customer Mobile:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-rep"
                              placeholder=""
                              name="custRepMob"
                              value={FormState.values.custRepMob}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-consultant" className="form-label">
                            Consultant *:
                            <div style={{ width: "10%", height: "10%", float: "right", marginTop: "0px" }}>
                              <a
                                className="btn-round btn-icon"
                                color="success"
                                size="sm"
                                style={{ padding: "5px", background: "#1ee0ac" }}
                                onClick={toggleConsltantForm}
                              >
                                <Icon name="plus" />
                              </a>
                            </div>
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              className="form-control form-control-sm"
                              id="default-consultant"
                              disabled={type == "view" ? true : false}
                              style={{ width: "85%" }}
                              name="consId"
                              value={FormState.values.consId}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            >
                              <option value="0">Consultant List</option>
                              {consultantList.map((c) => {
                                return (
                                  <option value={c.id} key={c.id}>
                                    {c.cName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="default-agency" className="form-label">
                            Agency *:
                            <div style={{ width: "10%", height: "10%", float: "right", marginTop: "0px" }}>
                              <a
                                className="btn-round btn-icon"
                                color="success"
                                size="sm"
                                style={{ padding: "5px", background: "#1ee0ac" }}
                                onClick={toggleAgencyForm}
                              >
                                <Icon name="plus" />
                              </a>
                            </div>
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              className="form-control form-control-sm"
                              id="default-agency"
                              disabled={type == "view" ? true : false}
                              style={{ width: "85%" }}
                              name="agencyId"
                              value={FormState.values.agencyId}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            >
                              <option value="0">Agency List</option>
                              {agencyList.map((c) => {
                                return (
                                  <option value={c.id} key={c.id}>
                                    {c.cName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="default-subcontractor" className="form-label">
                            Subcontractor *:
                            <div style={{ width: "10%", height: "10%", float: "right", marginTop: "0px" }}>
                              <a
                                className="btn-round btn-icon"
                                color="success"
                                size="sm"
                                style={{ padding: "5px", background: "#1ee0ac" }}
                                onClick={toggleSubContractorForm}
                              >
                                <Icon name="plus" />
                              </a>
                            </div>
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              className="form-control form-control-sm"
                              id="default-subcontractor"
                              disabled={type == "view" ? true : false}
                              style={{ width: "85%" }}
                              name="subContractId"
                              value={FormState.values.subContractId}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                            >
                              <option value="0">Subcontractor List</option>
                              {subcontractorList.map((c) => {
                                return (
                                  <option value={c.id} key={c.id}>
                                    {c.cName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-textarea" className="form-label" onKeyDown={handleEnter}>
                            Address
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              className="no-resize form-control"
                              type="textarea"
                              id="default-textarea"
                              name="address"
                              disabled={type == "view" ? true : false}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={FormState.values.address}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className="preview-block">
                            <span className="preview-title overline-title" style={{ color: "black" }}>
                              Performa Invoice Deliver To:
                            </span>
                            <div className="g-4 align-center flex-wrap">
                              <div className="g">
                                <div className="custom-control custom-control-sm custom-radio">
                                  <input
                                    type="radio"
                                    disabled={type == "view" ? true : false}
                                    className="custom-control-input form-control"
                                    name="deliveryType"
                                    id="customRadio7"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    onKeyDown={handleEnter}
                                    checked={FormState.values.deliveryType == "customer"}
                                    value={"customer"}
                                  />
                                  <label className="custom-control-label" htmlFor="customRadio7">
                                    Customer
                                  </label>
                                </div>
                              </div>
                              <div className="g">
                                <div className="custom-control custom-control-sm custom-radio">
                                  <input
                                    type="radio"
                                    disabled={type == "view" ? true : false}
                                    className="custom-control-input form-control"
                                    name="deliveryType"
                                    id="customRadio8"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    onKeyDown={handleEnter}
                                    value={"agency"}
                                    checked={FormState.values.deliveryType == "agency"}
                                  />
                                  <label className="custom-control-label" htmlFor="customRadio8">
                                    Agency
                                  </label>
                                </div>
                              </div>
                              <div className="g">
                                <div className="custom-control custom-control-sm custom-radio">
                                  <input
                                    type="radio"
                                    disabled={type == "view" ? true : false}
                                    className="custom-control-input form-control"
                                    name="deliveryType"
                                    id="customRadio9"
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    onKeyDown={handleEnter}
                                    checked={FormState.values.deliveryType == "subcontractor"}
                                    value={"subcontractor"}
                                  />
                                  <label className="custom-control-label" htmlFor="customRadio9">
                                    SubContractor
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-textarea" className="form-label" onKeyDown={handleEnter}>
                            Name Of Work:
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              className="no-resize form-control"
                              type="textarea"
                              id="default-textarea"
                              name="work_name"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={FormState.values.work_name}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label">
                            Mobile No:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-2"
                              name="contactNo"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.contactNo}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="default-3" className="form-label">
                            Test Witness *:
                          </Label>
                          <div className="form-control-wrap">
                            <div className="form-control-select">
                              <select
                                className="form-control form-control-sm"
                                id="default-4"
                                disabled={type == "view" ? true : false}
                                name="testWitness"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onKeyDown={handleEnter}
                                value={FormState.values.testWitness}
                              >
                                <option value="default_option">Default Option</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label">
                            Name of Representative *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-2"
                              name="uRep"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.uRep}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label">
                            Contact No *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-2"
                              name="uRepMob"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.uRepMob}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="default-2" className="form-label">
                          ZipCode:
                        </Label>
                        <div className="form-control-wrap">
                          <input className="form-control form-control-sm" type="text" id="default-2" 
                          name="zipcode"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.zipcode}/>
                        </div>
                      </FormGroup>
                      </Col> */}
                      {/* <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="default-3" className="form-label">
                          Phone No:
                        </Label>
                        <div className="form-control-wrap">
                          <input className="form-control form-control-sm" type="text" id="default-3" 
                          name="contactNo"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.contactNo}/>
                        </div>
                      </FormGroup>
                    </Col> */}
                      {/* <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="default-textarea" className="form-label">
                          Report Details:
                        </Label>
                        <div className="form-control-wrap">
                          <textarea
                            className="no-resize form-control"
                            type="textarea"
                            id="default-textarea"
                            name="reportDetail"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onKeyDown={handleEnter}
                            value={FormState.values.reportDetail}
                          />
                        </div>
                      </FormGroup>
                    </Col> */}

                      {/* <FormGroup>
                        <Label htmlFor="default-3" className="form-label">
                          Status:
                        </Label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <Input type="select" id="default-4" name="status" 
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onKeyDown={handleEnter}
                            value={FormState.values.status}>
                            <option value="Inward">Material Inward</option>
                              <option value="Pending">Pending</option>
                            </Input>
                          </div>
                        </div>
                      </FormGroup> */}

                      {/* <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="default-3" className="form-label">
                          Status:
                        </Label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <Input type="select" name="inwardType" id="default-4" value={FormState.values.inwardType}>
                              <option value="default_option">Default Option</option>
                              <option value="option_select_name">Option select name</option>
                              <option value="option_select_name">Option select name</option>
                            </Input>
                          </div>
                        </div>
                      </FormGroup>
                    </Col> */}

                      {/* <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="default-2" className="form-label">
                          Signature Date:
                        </Label>
                        <div className="form-control-wrap">
                        <label onKeyDown={handleEnter}>
                        <InputMask
                                        
                                        mask="99-99-9999"
                                        tabIndex={0}
                                        className="form-control form-control-sm"
                                        name="signDate"
                                        style={{ width: '100%' }}
                                        disabled={
                                          type === 'view' 
                                            ? true
                                            : false
                                        }
                                        onChange={e => {
                                          handleChange(e);
                                        }}
                                        onFocus={handleFocus}
                                        value={
                                          !FormState.values.signDate
                                            ? (FormState.values.signDate = moment(
                                                new Date()
                                              ).format('DD-MM-YYYY'))
                                            : FormState.values.signDate
                                        }
                                      />
                                      </label>
                        </div>
                      </FormGroup>
                    </Col> */}
                      {/* <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="default-3" className="form-label">
                          Test AS Per:
                        </Label>
                        <div className="form-control-wrap">
                          <div className="form-control-select">
                            <Input type="select" id="default-4" 
                            name="testType"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onKeyDown={handleEnter}
                            value={FormState.values.testType}>
                              <option value="0">Select</option>
                              <option value="IS Code">IS Code Specification</option>
                            </Input>
                          </div>
                        </div>
                      </FormGroup>
                    </Col> */}
                      {/* <Col sm="6">
                      <div className="preview-block">
                        <span className="preview-title overline-title"></span>
                        <div className="g-3 align-center flex-wrap">
                          <div className="g">
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="customCheck7" 
                              name="IsOffline"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.IsOffline}/>
                              <label className="custom-control-label" htmlFor="customCheck7">
                                Offline Test
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row className="gy-4">
                      {/* <Col sm="2">
                      <FormGroup>
                        <Label htmlFor="default-0" className="form-label">
                          Sr. No.:
                        </Label>
                        <div className="form-control-wrap">
                          <input 
                          className="form-control form-control-sm" 
                          disabled
                          type="text"
                          id="default-0" 
                          placeholder="" 
                          name="SrNo"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.SrNo}/>
                        </div>
                      </FormGroup>
                    </Col> */}
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Test Group *:
                            {/* <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            onClick={toggleMaterialForm}
                          >
                            Material
                          </a> */}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={
                                type == "view"
                                  ? // ||
                                    // FormState.values.materialArray.length > 0 ||
                                    // finalMaterialArray.length > 0
                                    true
                                  : false
                              }
                              className="form-control form-control-sm"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.tGrpId || ""}
                              name={"tGrpId"}
                            >
                              <option value="0"> Select Test Group</option>
                              {TestGroupList.map((t) => {
                                return (
                                  <option value={t.id} key={t.id}>
                                    {t.test_group_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="default-0" className="form-label">
                          Material Name:
                        </Label>
                        <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.mId || ""}
                          name={"mId"}
                        >
                          <option value="0"> Select Material Name</option>
                          {MaterialList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.mName}
                              </option>
                            );
                          })}
                        </select>
                        </div>
                      </FormGroup>
                    </Col> */}
                      <Col sm="6"></Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-textarea" className="form-label">
                            Material Name:
                          </Label>
                          <Card className="card-bordered card-preview" style={{ height: 400 }}>
                            <div style={{ height: "400px", overflow: "auto" }}>
                              <table className="table table-orders">
                                <thead className="tb-odr-head">
                                  <tr className="tb-odr-item">
                                    <th className="tb-odr-action">&nbsp;</th>
                                    <th className="tb-odr-info">
                                      <span className="tb-odr-id">Material ID</span>
                                    </th>
                                    <th className="tb-odr-info">
                                      <span className="tb-odr-date d-none d-md-inline-block">Material Name</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="tb-odr-body">
                                  {MaterialList.map((item) => {
                                    const newListArray = materialInfo.filter((el) => {
                                      return (el.id == item.id || el.mId == item.id) && el.isChecked == true;
                                    });
                                    const newSavedMaterialArray = FormState.values.materialArray.filter((p) => {
                                      return p.mId == item.id;
                                    });
                                    return (
                                      <tr className="tb-odr-item" key={item.id}>
                                        <td className="tb-odr-action">
                                          <div className="tb-odr-btns d-none d-md-inline">
                                            <Icon
                                              name="eye"
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                loadParameterList(e, item.id);
                                              }}
                                            />
                                            <div className="custom-control custom-control-sm custom-checkbox">
                                              <input
                                                type="checkbox"
                                                style={{
                                                  padding: "0px",
                                                }}
                                                disabled={type == "view" ? true : false}
                                                inputProps={{
                                                  "aria-label": "isCartoncheckbox",
                                                }}
                                                name={item.id}
                                                checked={newListArray.length > 0 ? true : false}
                                                onChange={(e, r) => {
                                                  const isChecked = e.target.checked;
                                                  createMaterialArray(isChecked, item.id);
                                                }}
                                                value={materialInfo}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td className="tb-odr-info">
                                          <span className="tb-odr-date">
                                            <span className="amount">{item.id}</span>
                                          </span>
                                        </td>
                                        <td className="tb-odr-info">
                                          <span className="tb-odr-date">
                                            <span className="amount"> {item.mName} </span>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </Card>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-textarea" className="form-label">
                            Test Name:
                          </Label>
                          <Card className="card-bordered card-preview" style={{ height: 400 }}>
                            <div style={{ height: "400px", overflow: "auto" }}>
                              <table className="table table-orders">
                                <thead className="tb-odr-head">
                                  <tr className="tb-odr-item">
                                    <th className="tb-odr-action">&nbsp;</th>
                                    <th className="tb-odr-info">
                                      <span className="tb-odr-date d-none d-md-inline-block">Parameter Name</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="tb-odr-body">
                                  {TestMasterList.map((item) => {
                                    const newListArray = parameterInfo.filter((el) => {
                                      return (el.id == item.id || el.tId == item.id) && el.isChecked == true;
                                    });
                                    const newSavedParaArray = FormState.values.paraArray.filter((p) => {
                                      return p.id == item.id;
                                    });
                                    return (
                                      <tr className="tb-odr-item" key={item.id}>
                                        <td className="tb-odr-action">
                                          <div className="tb-odr-btns d-none d-md-inline">
                                            <div className="custom-control custom-control-sm custom-checkbox">
                                              <input
                                                type="checkbox"
                                                style={{
                                                  padding: "0px",
                                                }}
                                                disabled={type == "view" ? true : false}
                                                inputProps={{
                                                  "aria-label": "isCartoncheckbox",
                                                }}
                                                name={item.id}
                                                checked={newListArray.length > 0 ? true : false}
                                                onChange={(e, r) => {
                                                  const isChecked = e.target.checked;
                                                  createParameterArray(isChecked, item.id);
                                                }}
                                                value={parameterInfo}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        {/* <td className="tb-odr-info">
                                  <span className="tb-odr-date">
                                    <span className="amount">{item.id}</span>
                                  </span>
                                </td> */}
                                        <td className="tb-odr-info">
                                          <span className="tb-odr-date">
                                            <span className="amount">{item.pName}</span>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </Card>

                          <div className="form-control-wrap">
                            {/* <select
                          multiple
                          style={{height:'300px',fontSize:14}}
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.tId || ""}
                          name={"tId"}
                        >
                          <option value="0"> Select Test Name</option>
                          {TestMasterList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.pName}
                              </option>
                            );
                          })}
                        </select> */}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label">
                            Grade *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-3"
                              name="gradeId"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.gradeId}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label">
                            Casting:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              disabled={type == "view" ? true : false}
                              id="default-0"
                              name="castDate"
                              showMaskOnHover={true}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.castDate}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-3" className="form-label">
                            Making / Batch No. / Type / Dia *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-3"
                              name="batchNo"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.batchNo}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="default-3" className="form-label">
                            Total No. Sample *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-3"
                              name="totalSample"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.totalSample}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="default-2" className="form-label">
                            Total No. Sets *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-2"
                              name="totalSet"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.totalSet}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="default-3" className="form-label">
                            {"UOM *"}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              name="suom"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.suom}
                            >
                              <option></option>
                              {UnitMasterList.map((u) => {
                                return (
                                  <option value={u.uom} key={u.uom}>
                                    {u.uom}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Expected Total No.of Sample:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-0"
                              placeholder=""
                              name="expTotalSample"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.expTotalSample}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-textarea" className="form-label" onKeyDown={handleEnter}>
                            Customer Communication / Specification *:
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              className="no-resize form-control"
                              disabled={type == "view" ? true : false}
                              type="textarea"
                              id="default-textarea"
                              name="cSpec"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={FormState.values.cSpec}
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      {/* <Col sm="3">
                      <div className="preview-block">
                        <span className="preview-title overline-title"></span>
                        <div className="g-3 align-center flex-wrap">
                          <div className="g">
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="customCheck8" 
                              name="mCollection" 
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.mCollection}/>
                              <label className="custom-control-label" htmlFor="customCheck8">
                                Material Collection
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                    </Row>
                    <Row className="gy-4">
                      {/* <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="default-textarea" className="form-label">
                          Test Specification:
                        </Label>
                        <div className="form-control-wrap">
                          <textarea
                            className="no-resize form-control"
                            type="textarea"
                            id="default-textarea"
                            name="testSpec"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onKeyDown={handleEnter}
                            value={FormState.values.testSpec}
                          />
                        </div>
                      </FormGroup>
                    </Col> */}
                      {/* <Col sm="6"> */}
                      {/* <FormGroup>
                        <Label htmlFor="default-2" className="form-label">
                          Casting Date:
                        </Label>
                        <div className="form-control-wrap">
                        <input className="form-control form-control-sm"
                            type="date"
                            id="default-0"
                            name="castDate"
                            showMaskOnHover={true}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onKeyDown={handleEnter}
                            value={FormState.values.castDate}
                          />
                        </div>
                      </FormGroup> */}
                      {/* <FormGroup>
                        <Row className="gy-4">
                          <Col sm="9">
                            <Label htmlFor="default-3" className="form-label">
                              Qty *:
                            </Label>
                            <div className="form-control-wrap">
                              <input className="form-control form-control-sm" type="text" id="default-3" 
                              name="qty"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.qty}
                              />
                            </div>
                          </Col>
                          <Col sm="3">
                            <Label htmlFor="default-3" className="form-label">
                              {"UOM  *"}
                            </Label>
                            <div className="form-control-wrap">
                              <select className="form-control form-control-sm" name="quom"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.quom}>
                                <option></option>
                                {UnitMasterList.map(u => {
                              return (
                                <option value={u.id} key={u.uom}>
                                  {u.uom}
                                </option>
                              );
                            })}
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </FormGroup> */}
                      {/* </Col> */}

                      {/* <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="default-0" className="form-label">
                          Tester Name:
                        </Label>
                        <div className="form-control-wrap">
                          <select className="form-control form-control-sm" name="testerId"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.testerId}>
                            <option></option>
                            {EmployeeList.map(u => {
                              return (
                                <option value={u.id} key={u.id}>
                                  {u.eName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </FormGroup>
                    </Col> */}
                      {/* <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="default-0" className="form-label">
                          Approver Name:
                        </Label>
                        <div className="form-control-wrap">
                          <select className="form-control form-control-sm" name="approvId"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.approvId}>
                            <option></option>
                            {EmployeeList.map(u => {
                              return (
                                <option value={u.id} key={u.id}>
                                  {u.eName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </FormGroup>
                    </Col> */}

                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Sample Condition *:
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              name="sampleCondition"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.sampleCondition}
                            >
                              <option></option>
                              <option value="Sealed">Sealed</option>
                              <option value="Opened">Opened</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Sample Procedure:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-0"
                              placeholder=""
                              name="sampleProc"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.sampleProc}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Sample / Collected By *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-0"
                              placeholder=""
                              name="sampleBy"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.sampleBy}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Test to be Performed (In Days):
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              disabled={type == "view" ? true : false}
                              type="text"
                              id="default-0"
                              placeholder=""
                              name="testPerDays"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.testPerDays}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="2">
                      <FormGroup>
                        <Label htmlFor="default-0" className="form-label">
                          Testing Date:
                        </Label>
                        <div className="form-control-wrap">
                        <input className="form-control form-control-sm"
                            type="date"
                            id="default-0"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onKeyDown={handleEnter}
                            name="testDate"
                            showMaskOnHover={true}
                            value={FormState.values.testDate}
                          />
                        </div>
                      </FormGroup>
                    </Col> */}
                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Expected Date of Reporting *:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-sm"
                              type="date"
                              disabled={type == "view" ? true : false}
                              id="default-0"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              name="rptDate"
                              value={FormState.values.rptDate}
                              showMaskOnHover={true}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="4">
                      <div className="preview-block">
                        <span className="preview-title overline-title"></span>
                        <div className="g-3 align-center flex-wrap">
                          <div className="g">
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="customCheck9" 
                              name="mReceive" 
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handlmReceiveeEnter}
                              value={FormState.values.mReceive}/>
                              <label className="custom-control-label" htmlFor="customCheck9">
                                Material Received
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="preview-block">
                        <span className="preview-title overline-title"></span>
                        <div className="g-3 align-center flex-wrap">
                          <div className="g">
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="customCheck10" 
                              name="interCast" 
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormState.values.interCast}/>
                              <label className="custom-control-label" htmlFor="customCheck10">
                                Internal Casting
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                    </Row>
                    <Row className="gy-4">
                      <Col sm="2">
                        <Button
                          color="success"
                          name="Save"
                          style={{ display: type == "view" ? "none" : "" }}
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          color="success"
                          name="Save"
                          style={{ display: type == "view" ? "" : "none" }}
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          Modify
                        </Button>
                      </Col>
                      <Col sm="2">
                        <Button
                          color="danger"
                          name="Delete"
                          onClick={(e) => {
                            handleDelete(e);
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                      <Col sm="2">
                        <Button
                          color="primary"
                          name="Cancel"
                          onClick={(e) => {
                            handleCancel(e);
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
                <Modal isOpen={modalMaterialForm} toggle={toggleMaterialForm}>
                  <ModalHeader
                    toggle={toggleMaterialForm}
                    close={
                      <button className="close" onClick={toggleMaterialForm}>
                        <Icon name="cross" />
                      </button>
                    }
                  >
                    Select Material Type
                  </ModalHeader>
                  <ModalBody>
                    <Card className="card-bordered card-preview">
                      <table className="table table-orders">
                        <thead className="tb-odr-head">
                          <tr className="tb-odr-item">
                            <th className="tb-odr-action">&nbsp;</th>
                            <th className="tb-odr-info">
                              <span className="tb-odr-id">Material ID</span>
                            </th>
                            <th className="tb-odr-info">
                              <span className="tb-odr-date d-none d-md-inline-block">Material Name</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tb-odr-body">
                          {MaterialList.map((item) => {
                            const newListArray = materialInfo.filter((el) => {
                              return (el.id == item.id || el.mId == item.id) && el.isChecked == true;
                            });
                            const newSavedMaterialArray = FormState.values.materialArray.filter((p) => {
                              return p.mId == item.id;
                            });
                            return (
                              <tr className="tb-odr-item" key={item.id}>
                                <td className="tb-odr-action">
                                  <div className="tb-odr-btns d-none d-md-inline">
                                    <Icon
                                      name="eye"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        loadParameterList(e, item.id);
                                      }}
                                    />
                                    <div className="custom-control custom-control-sm custom-checkbox">
                                      <input
                                        type="checkbox"
                                        style={{
                                          padding: "0px",
                                        }}
                                        inputProps={{
                                          "aria-label": "isCartoncheckbox",
                                        }}
                                        name={item.id}
                                        checked={
                                          newListArray.length > 0 || newSavedMaterialArray.length > 0 ? true : false
                                        }
                                        onChange={(e, r) => {
                                          const isChecked = e.target.checked;
                                          createMaterialArray(isChecked, item.id);
                                        }}
                                        value={materialInfo}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td className="tb-odr-info">
                                  <span className="tb-odr-date">
                                    <span className="amount">{item.id}</span>
                                  </span>
                                </td>
                                <td className="tb-odr-info">
                                  <span className="tb-odr-date">
                                    <span className="amount"> {item.mName} </span>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Card>
                  </ModalBody>
                  <ModalFooter className="bg-light">
                    <FormGroup>
                      <Button
                        color="success"
                        name="Save"
                        onClick={(e) => {
                          handleMaterialSave(e);
                        }}
                      >
                        Save
                      </Button>
                      <Button color="primary" name="Save" onClick={toggleMaterialForm}>
                        Cancel
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Modal>
                {/* <Modal isOpen={modalParameterForm} toggle={toggleParameterForm}>
                <ModalHeader
                  toggle={toggleParameterForm}
                  close={
                    <button className="close" onClick={toggleParameterForm}>
                      <Icon name="cross" />
                    </button>
                  }
                >
                  Parameter List
                </ModalHeader>
                <ModalBody>
                <Card className="card-bordered card-preview">
                <table className="table table-orders">
                  <thead className="tb-odr-head">
                    <tr className="tb-odr-item">
                    <th className="tb-odr-action">&nbsp;</th>
                      <th className="tb-odr-info">
                        <span className="tb-odr-id">Parameter ID</span>
                      </th>
                      <th className="tb-odr-info">
                        <span className="tb-odr-date d-none d-md-inline-block">Parameter Name</span>
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody className="tb-odr-body">
                    {TestMasterList.map((item) => {
                      const newListArray = parameterInfo.filter(el => {
                        return (el.id == item.id || el.tId == item.id) && el.isChecked == true;
                      });
                      const newSavedParaArray = FormState.values.paraArray.filter(p => {
                        return p.tId == item.id;
                      })
                      return (
                        <tr className="tb-odr-item" key={item.id}>
                          <td className="tb-odr-action">
                            <div className="tb-odr-btns d-none d-md-inline">
                            <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                                        type="checkbox"
                                        style={{
                                          padding: '0px'
                                        }}
                                        inputProps={{
                                          'aria-label': 'isCartoncheckbox'
                                        }}
                                        name={item.id}
                                        checked={
                                          newListArray.length > 0 || newSavedParaArray.length > 0 ? true : false
                                        }
                                        onChange={(e, r) => {
                                          const isChecked = e.target.checked;
                                          createParameterArray(
                                            isChecked,
                                            item.id
                                          );
                                        }}
                                        value={parameterInfo}
                                      />
                            </div>
                            </div>
                          </td>
                          <td className="tb-odr-info">
                            <span className="tb-odr-date">
                              <span className="amount">{item.id}</span>
                            </span>
                          </td>
                          <td className="tb-odr-info">
                            <span className="tb-odr-date">
                              <span className="amount">{item.pName}</span>
                            </span>
                          </td>
                          
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                </Card>
                </ModalBody>
                <ModalFooter className="bg-light">
                  <FormGroup>
                    <Button color="success" name="Save" onClick={(e) => {
                        handleParaSave(e);
                      }}>
                      Save
                    </Button>
                    <Button color="primary" name="Save" onClick={toggleParameterForm}>
                      Cancel
                    </Button>
                  </FormGroup>
                </ModalFooter>
              </Modal> */}
                <Modal isOpen={modalForm} toggle={toggleForm}>
                  <ModalHeader
                    toggle={toggleForm}
                    close={
                      <button className="close" onClick={toggleForm}>
                        <Icon name="cross" />
                      </button>
                    }
                  >
                    Customer Info
                  </ModalHeader>
                  <ModalBody>
                    <Row className="gy-4">
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Customer Code:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled
                              className="form-control form-control-sm"
                              type="hidden"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.id || ""}
                              name={"id"}
                            />
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cCode || ""}
                              name={"cCode"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Customer Type:
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cType || ""}
                              name={"cType"}
                            >
                              <option value="0">Select</option>
                              <option value="GOV">Goverment</option>
                              <option values="SGOV">Semi-Goverment</option>
                              <option value="GEN">General</option>
                              <option value="OTH">Others</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Customer Name:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cName || ""}
                              name={"cName"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Contact Person:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cPerson || ""}
                              name={"cPerson"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Contact Number:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cNumberOne || ""}
                              name="cNumberOne"
                            />
                            {/* <input className="form-control form-control-sm" type="text" id="default-0" placeholder="" /> */}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Alternative Number:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cNumberTwo || ""}
                              name="cNumberTwo"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Email:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cEmail || ""}
                              name="cEmail"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label" onKeyDown={handleCustEnter}>
                            Registered Office Address:
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              cols={2}
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              value={FormCustState.values.cAddressOne || ""}
                              name="cAddressOne"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Side Address:
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          cols={2}
                          onChange={(e) => {
                            handleCustChange(e);
                          }}
                          onKeyDown={handleCustEnter}
                          value={FormCustState.values.cAddressTwo || ""}
                          name="cAddressTwo"
                        />
                      </div>
                    </FormGroup>
                    </Col> */}
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            City:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>

                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cCity || ""}
                              name="cCity"
                            >
                              <option>Select</option>
                              {CityList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.CityName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            State:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleStateForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cState || ""}
                              name="cState"
                            >
                              <option>Select</option>
                              {StateList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.StateName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Country:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleCountryForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cCountry || ""}
                              name="cCountry"
                            >
                              <option>Select</option>
                              {CountryList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.CountryName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="gy-4">
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            PAN No:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cPanCard || ""}
                              name="cPanCard"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            GST No:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={FormCustState.values.cGst || ""}
                              name="cGst"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Payment Term:
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormCustState.values.pId || ""}
                              name="pId"
                            >
                              <option>Select</option>
                              {PaymentList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.pName}
                                  </option>
                                );
                              })}
                            </select>
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="hidden"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleCustChange(e);
                              }}
                              onKeyDown={handleCustEnter}
                              value={(FormCustState.values.cCategory = "CUS")}
                              name="cCategory"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter className="bg-light">
                    <FormGroup>
                      <Button
                        color="success"
                        name="Save"
                        onClick={(e) => {
                          handleCustSave(e);
                        }}
                      >
                        Save
                      </Button>
                      <Button color="primary" name="Save" onClick={toggleForm}>
                        Cancel
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Modal>
                <Modal isOpen={modalAgencyForm} toggle={toggleAgencyForm}>
                  <ModalHeader
                    toggle={toggleAgencyForm}
                    close={
                      <button className="close" onClick={toggleAgencyForm}>
                        <Icon name="cross" />
                      </button>
                    }
                  >
                    Agency Info
                  </ModalHeader>
                  <ModalBody>
                    <Row className="gy-4">
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Agency Code:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled
                              className="form-control form-control-sm"
                              type="hidden"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.id || ""}
                              name={"id"}
                            />
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cCode || ""}
                              name={"cCode"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Agency Type:
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cType || ""}
                              name={"cType"}
                            >
                              <option value="0">Select</option>
                              <option value="GOV">Goverment</option>
                              <option values="SGOV">Semi-Goverment</option>
                              <option value="GEN">General</option>
                              <option value="OTH">Others</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Customer Name:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cName || ""}
                              name={"cName"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Contact Person:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cPerson || ""}
                              name={"cPerson"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Contact Number:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cNumberOne || ""}
                              name="cNumberOne"
                            />
                            {/* <input className="form-control form-control-sm" type="text" id="default-0" placeholder="" /> */}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Alternative Number:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cNumberTwo || ""}
                              name="cNumberTwo"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Email:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cEmail || ""}
                              name="cEmail"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label" onKeyDown={handleAgencyEnter}>
                            Registered Office Address:
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              cols={2}
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              value={FormAgencyState.values.cAddressOne || ""}
                              name="cAddressOne"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Side Address:
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          cols={2}
                          onChange={(e) => {
                            handleAgencyChange(e);
                          }}
                          onKeyDown={handleAgencyEnter}
                          value={FormAgencyState.values.cAddressTwo || ""}
                          name="cAddressTwo"
                        />
                      </div>
                    </FormGroup>
                    </Col> */}
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            City:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>

                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cCity || ""}
                              name="cCity"
                            >
                              <option>Select</option>
                              {CityList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.CityName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            State:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleStateForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cState || ""}
                              name="cState"
                            >
                              <option>Select</option>
                              {StateList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.StateName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Country:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleCountryForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cCountry || ""}
                              name="cCountry"
                            >
                              <option>Select</option>
                              {CountryList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.CountryName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            PAN No:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cPanCard || ""}
                              name="cPanCard"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            GST No:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={FormAgencyState.values.cGst || ""}
                              name="cGst"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Payment Term:
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormAgencyState.values.pId || ""}
                              name="pId"
                            >
                              <option>Select</option>
                              {PaymentList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.pName}
                                  </option>
                                );
                              })}
                            </select>
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="hidden"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleAgencyChange(e);
                              }}
                              onKeyDown={handleAgencyEnter}
                              value={(FormAgencyState.values.cCategory = "AGN")}
                              name="cCategory"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter className="bg-light">
                    <FormGroup>
                      <Button
                        color="success"
                        name="Save"
                        onClick={(e) => {
                          handleAgencySaveEntry(e);
                        }}
                      >
                        Save
                      </Button>
                      <Button color="primary" name="Save" onClick={toggleAgencyForm}>
                        Cancel
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Modal>
                <Modal isOpen={modalConsultantForm} toggle={toggleConsltantForm}>
                  <ModalHeader
                    toggle={toggleConsltantForm}
                    close={
                      <button className="close" onClick={toggleConsltantForm}>
                        <Icon name="cross" />
                      </button>
                    }
                  >
                    Consultant Info
                  </ModalHeader>
                  <ModalBody>
                    <Row className="gy-4">
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Consultant Code:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled
                              className="form-control form-control-sm"
                              type="hidden"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.id || ""}
                              name={"id"}
                            />
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cCode || ""}
                              name={"cCode"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Consultant Type:
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cType || ""}
                              name={"cType"}
                            >
                              <option value="0">Select</option>
                              <option value="GOV">Goverment</option>
                              <option values="SGOV">Semi-Goverment</option>
                              <option value="GEN">General</option>
                              <option value="OTH">Others</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Consultant Name:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cName || ""}
                              name={"cName"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Contact Person:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cPerson || ""}
                              name={"cPerson"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Contact Number:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cNumberOne || ""}
                              name="cNumberOne"
                            />
                            {/* <input className="form-control form-control-sm" type="text" id="default-0" placeholder="" /> */}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Alternative Number:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cNumberTwo || ""}
                              name="cNumberTwo"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Email:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cEmail || ""}
                              name="cEmail"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label" onKeyDown={handleConsEnter}>
                            Registered Office Address:
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              cols={2}
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              value={FormConsState.values.cAddressOne || ""}
                              name="cAddressOne"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Side Address:
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          cols={2}
                          onChange={(e) => {
                            handleConsChange(e);
                          }}
                          onKeyDown={handleConsEnter}
                          value={FormConsState.values.cAddressTwo || ""}
                          name="cAddressTwo"
                        />
                      </div>
                    </FormGroup>
                    </Col> */}
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            City:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>

                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cCity || ""}
                              name="cCity"
                            >
                              <option>Select</option>
                              {CityList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.CityName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            State:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleStateForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cState || ""}
                              name="cState"
                            >
                              <option>Select</option>
                              {StateList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.StateName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Country:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleCountryForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cCountry || ""}
                              name="cCountry"
                            >
                              <option>Select</option>
                              {CountryList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.CountryName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            PAN No:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cPanCard || ""}
                              name="cPanCard"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            GST No:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={FormConsState.values.cGst || ""}
                              name="cGst"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Payment Term:
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormConsState.values.pId || ""}
                              name="pId"
                            >
                              <option>Select</option>
                              {PaymentList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.pName}
                                  </option>
                                );
                              })}
                            </select>
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="hidden"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleConsChange(e);
                              }}
                              onKeyDown={handleConsEnter}
                              value={(FormConsState.values.cCategory = "CON")}
                              name="cCategory"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter className="bg-light">
                    <FormGroup>
                      <Button
                        color="success"
                        name="Save"
                        onClick={(e) => {
                          handleConsSaveEntry(e);
                        }}
                      >
                        Save
                      </Button>
                      <Button color="primary" name="Save" onClick={toggleConsltantForm}>
                        Cancel
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Modal>
                <Modal isOpen={modelContractorForm} toggle={toggleSubContractorForm}>
                  <ModalHeader
                    toggle={toggleSubContractorForm}
                    close={
                      <button className="close" onClick={toggleSubContractorForm}>
                        <Icon name="cross" />
                      </button>
                    }
                  >
                    SubContractor Info
                  </ModalHeader>
                  <ModalBody>
                    <Row className="gy-4">
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            SubContractor Code:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled
                              className="form-control form-control-sm"
                              type="hidden"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.id || ""}
                              name={"id"}
                            />
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cCode || ""}
                              name={"cCode"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            SubContractor Type:
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cType || ""}
                              name={"cType"}
                            >
                              <option value="0">Select</option>
                              <option value="GOV">Goverment</option>
                              <option values="SGOV">Semi-Goverment</option>
                              <option value="GEN">General</option>
                              <option value="OTH">Others</option>
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            SubContractor Name:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cName || ""}
                              name={"cName"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Contact Person:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cPerson || ""}
                              name={"cPerson"}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Contact Number:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cNumberOne || ""}
                              name="cNumberOne"
                            />
                            {/* <input className="form-control form-control-sm" type="text" id="default-0" placeholder="" /> */}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Alternative Number:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cNumberTwo || ""}
                              name="cNumberTwo"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Email:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cEmail || ""}
                              name="cEmail"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="gy-4">
                      <Col sm="12">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label" onKeyDown={handleContractorEnter}>
                            Registered Office Address:
                          </Label>
                          <div className="form-control-wrap">
                            <textarea
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              cols={2}
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              value={FormContractorState.values.cAddressOne || ""}
                              name="cAddressOne"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Side Address:
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          cols={2}
                          onChange={(e) => {
                            handleContractorChange(e);
                          }}
                          onKeyDown={handleContractorEnter}
                          value={FormContractorState.values.cAddressTwo || ""}
                          name="cAddressTwo"
                        />
                      </div>
                    </FormGroup>
                    </Col> */}
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            City:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>

                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cCity || ""}
                              name="cCity"
                            >
                              <option>Select</option>
                              {CityList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.CityName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            State:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleStateForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cState || ""}
                              name="cState"
                            >
                              <option>Select</option>
                              {StateList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.StateName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Country:
                            {/* <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            // onClick={toggleCountryForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div> */}
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cCountry || ""}
                              name="cCountry"
                            >
                              <option>Select</option>
                              {CountryList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.CountryName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="gy-4">
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            PAN No:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cPanCard || ""}
                              name="cPanCard"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            GST No:
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={FormContractorState.values.cGst || ""}
                              name="cGst"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="default-0" className="form-label">
                            Payment Term:
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="text"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleEnter}
                              value={FormContractorState.values.pId || ""}
                              name="pId"
                            >
                              <option>Select</option>
                              {PaymentList.map((m) => {
                                return (
                                  <option value={m.id} key={m.id}>
                                    {m.pName}
                                  </option>
                                );
                              })}
                            </select>
                            <input
                              disabled={type == "view" ? true : false}
                              className="form-control form-control-sm"
                              type="hidden"
                              id="default-0"
                              placeholder=""
                              onChange={(e) => {
                                handleContractorChange(e);
                              }}
                              onKeyDown={handleContractorEnter}
                              value={(FormContractorState.values.cCategory = "SCN")}
                              name="cCategory"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter className="bg-light">
                    <FormGroup>
                      <Button
                        color="success"
                        name="Save"
                        onClick={(e) => {
                          handleSubContractorSave(e);
                        }}
                      >
                        Save
                      </Button>
                      <Button color="primary" name="Save" onClick={toggleSubContractorForm}>
                        Cancel
                      </Button>
                    </FormGroup>
                  </ModalFooter>
                </Modal>

                <Backdrop className={classes.backdrop} open={PdfLoading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </form>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default JobInward;
