import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  ReactDataTable,
  
} from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import { GetApiLink, getRequestConfig } from "../../utils/Utils";
import axios from "axios";
import DataTable from "react-data-table-component";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
  	<input
    	id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button className="close" onClick={onClear}>
      <Icon name="cross-sm" />
    </button>
    </>
  );
  
const ParameterMasterList = () => {
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [ParameterList, setParameterList] = useState([]);
  const [pending, setPending] = React.useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');
  const history = useHistory();
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  
  function fetchParameterList() {
    axios
      .get(`${GetApiLink()}api/parameterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Parameter List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        const timeout = setTimeout(() => {
          setParameterList(response.data.data);
          setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Parameter List Error: ", error);
      });
  }
  useEffect(() => {
    fetchParameterList();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  const dataTableColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      grow:1
    },
    {
      name: "Parameter Name",
      selector: (row) => row.pName,
      sortable: true,
      grow:4
    },
    {
      name: "Group Name",
      selector: (row) => row.gName,
      sortable: true,
      grow:2
    },
    {
      name: "Material Name",
      selector: (row) => row.mName,
      sortable: true,
      grow:2
    },
    {
      Header: "Action",
      accessor: "action",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <Button color="primary" size="sm" aria-hidden="true" onClick={(e) => onRowClick(e, row.id)}>
            View
          </Button>
        </div>
      ),
    },
  ];
	const filteredItems = ParameterList && ParameterList.filter(
		item => item.pName && item.pName.toLowerCase().includes(filterText.toLowerCase()),
	);

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);
  const handleButtonClick = (e, row) => {
    alert(row);
  };
  
  // const DataTableData = [
  //   {
  //     id: 1,
  //     parametername: "Bulk Density",
  //     testname: "Coarse Aggregate",
  //     testgroup: "Aggregate",
  //   },
  // ];
  const onRowClick = (e, v) => {
    history.push(`${process.env.PUBLIC_URL}/view/Master/ParameterMaster/view/${v}`);
  };
  const AddNewData = (e) => {
    history.push(`${process.env.PUBLIC_URL}/view/Master/ParameterMaster/add/0`);
  };
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              {/* <div className={`code-block code-block-clean }`}>
                <OverlineTitle className="title">{"Parameter List"}</OverlineTitle>
                <Button
                  color="primary"
                  onClick={(e) => {
                    AddNewData(e);
                  }}
                  size="sm"
                  className="clipboard-init"
                  style={{ backgroundColor: "#854fff" }}
                >
                  {"Add New"}
                </Button>
              </div> */}
              <Block size="lg">
                <PreviewCard>
                {/* <DataTable 
                  title="Parameter List"
                  columns={dataTableColumns}
                  data={ParameterList}
                  pagination
                  actions={<Button
                    color="primary"
                    onClick={(e) => {
                      AddNewData(e);
                    }}
                    size="sm"
                    className="clipboard-init"
                    style={{ backgroundColor: "#854fff" }}
                  >
                    {"Add New"}
                  </Button>}
                  highlightOnHover
                  subHeader
                  subHeaderComponent={<input type="text" placeholder="Search Here" className="w-25 form-control"/>}
                  /> */}
                  <DataTable
                  title="Parameter List"
                    data={filteredItems}
                    columns={dataTableColumns}
                    progressPending={pending}
                    progressComponent={<Button disabled color="primary">
                    <Spinner style={{ width: "3rem", height: "3rem" }}/>
                  </Button>
                  }
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    actions={<Button
                      color="primary"
                      onClick={(e) => {
                        AddNewData(e);
                      }}
                      size="sm"
                      className="clipboard-init"
                      style={{ backgroundColor: "#854fff" }}
                    >
                      {"Add New"}
                    </Button>}
                  />
                  {/* <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>SRNO</th>
                        <th>Parameter Name</th>
                        <th>Group</th>
                        <th>Material Type</th>
                        <th>Test Type</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ParameterList.map((d) => {
                        return (
                          <tr>
                            <td>{d.id}</td>
                            <td>{d.pName}</td>
                            <td>{d.gName}</td>
                            <td>{d.mName}</td>
                            <td>{d.tName}</td>
                            <td>
                              <Button
                                color="primary"
                                size="sm"
                                className="clipboard-init"
                                style={{ backgroundColor: "#854fff" }}
                                onClick={(e) => {
                                  onRowClick(e, d.id, "edit");
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table> */}
                </PreviewCard>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default ParameterMasterList;
