import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import classnames from "classnames";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataCard from "../../components/partials/default/DataCard";

import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  CardLink,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  Button,
} from "../../components/Component";

import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import ReactInputDateMask from "react-input-date-mask";
import { GetApiLink, getRequestConfig, getUser } from "../../utils/Utils";
import swal from "sweetalert";
import axios from "axios";
import InputMask from "react-input-mask";
import moment from "moment";
import { IconButton } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));
const SampleInward = (props) => {
  const { match, history } = props;
  const { id, type } = match.params;
  const classes = useStyles();
  const [PdfLoading, setPdfLoading] = useState(false);
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [modelParaForm, setParaForm] = useState(false);
  const toggleParaTestModel = () => setParaForm(!modelParaForm);
  const [suggestion, setSuggestion] = useState([]);
  // function to change the design view under 990 px
  const [FormState, setFormState] = useState({
    values: {
      paradetail: [],
      paraInputDetail: [],
    },
  });
  const [FormParaState, setFormParaState] = useState({
    values: {},
  });
  const [FormContractorState, setFormContractorState] = useState({
    values: {},
  });
  const [TestGroupList, setTestGroupList] = useState([]);
  const [MaterialList, setMaterialist] = useState([]);
  const [valid, setValid] = useState(false);
  const [date, setDate] = useState();
  const [getResult, setResult] = useState([]);
  const [getFinalResult, setFinalResult] = useState([]);
  const [resultParaId, setResultParaId] = useState(0);
  let keysPressed = {};
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  const SoShow = (testId) => {
    setPdfLoading(true);
    // if (id === "") {
    //   //setPdfLoading(false);
    //   history.push(`${process.env.PUBLIC_URL}/view/Inward/SampleInwardList`);
    // }
    axios
      .get(
        `${GetApiLink()}api/sampleinward/${testId}`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Sample Inward Data Show Get: ", response);
        //console.log('A',response.data.response.error);
        if (typeof response.data.error != "undefined" && response.data.error == "true") {
          swal({
            icon: "error",
            title: "Opps!!!",
            text: response.data.message,
            type: "error",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Inward/SampleInwardList`);
          });
        } else {
          // if (response.data.response.isauth == "_AUTHFAILED") {
          //   history.push("/auth/logout");
          // } else {
          let paradetail = response.data.formula_data[0];
          let paraInputDetail = response.data.formula_data[1];
          if (type === "add") {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ...response.data.data,
                paradetail,
                paraInputDetail,
              },
            }));
            setSuggestion(response.data.formula_data[1]);
          } else {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ...response.data.data[0],
                paradetail,
                paraInputDetail,
              },
            }));
          }
          //}
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Sample Inward Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  };
  const handleFocus = (event) => event.target.select();
  const handleResult = (event, index, pId) => {
    console.log("Viral ", event.target.name);
    const tempArray =
      suggestion &&
      suggestion.filter((el) => {
        return el.paraId == pId && el.formula_name;
      });
    const viral =
      tempArray &&
      tempArray.filter((el, i) => {
        if (el.formula_name == event.target.name) {
          el.value = event.currentTarget.value;
        }
        return el;
      });
    setResult(viral);
    console.log("Result ", viral);
  };
  const handleChange = (event, index, pId) => {
    // setFormParaState((FormParaState) => ({
    //   ...FormParaState,
    //   values: {
    //     ...FormParaState.values,
    //     [event.target.name]: event.target.value,
    //   },
    // }));
  };
  function handleEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
        setFormParaState((FormParaState) => ({
          ...FormParaState,
          values: {
            ...FormParaState.values,
            [event.target.name]: event.target.value,
          },
        }));
      }
    }
  }
  let testInput = [];
  const handleOpenParaForm = (event, paraId) => {
    const pArray =
      FormState.values.paradetail &&
      FormState.values.paradetail.filter((el) => {
        return el.paraId == paraId;
      });
    console.log("Viral", pArray);
    //FormParaState.values.paraId = paraId;
    setFormParaState((FormParaState) => ({
      ...FormParaState,
      values: {
        ...FormParaState.values,
        ["paraId"]: paraId,
        ["paraName"]: pArray[0].paraName,
        ["fDesc"]: pArray[0].fDesc,
        ["fName"]: pArray[0].fName,
      },
    }));

    toggleParaTestModel();
  };
  const CheckContractorData = () => {
    setPdfLoading(false);
    if (
      FormContractorState.values.cCode === "" ||
      typeof FormContractorState.values.cCode === "undefined" ||
      FormContractorState.values.cCode === null
    ) {
      swal("Please select valid code");
      return false;
    } else if (
      FormContractorState.values.cName === "" ||
      typeof FormContractorState.values.cName === "undefined" ||
      FormContractorState.values.cName === null
    ) {
      swal("Please select valid Name");
      return false;
    } else if (
      FormContractorState.values.cType === "" ||
      typeof FormContractorState.values.cType === "undefined" ||
      FormContractorState.values.cType === null ||
      FormContractorState.values.cType === "0"
    ) {
      swal("Please select valid Customer type");
      return false;
    } else if (
      FormContractorState.values.cNumberOne === "" ||
      typeof FormContractorState.values.cNumberOne === "undefined" ||
      FormContractorState.values.cNumberOne === null
    ) {
      swal("Please select valid Contact Number");
      return false;
    } else if (
      FormContractorState.values.cAddressOne === "" ||
      typeof FormContractorState.values.cAddressOne === "undefined" ||
      FormContractorState.values.cAddressOne === null
    ) {
      swal("Please select valid Address");
      return false;
    } else if (
      FormContractorState.values.cPanCard === "" ||
      typeof FormContractorState.values.cPanCard === "undefined" ||
      FormContractorState.values.cPanCard === null
    ) {
      swal("Please select valid Pancard");
      return false;
    } else if (
      FormContractorState.values.cGst === "" ||
      typeof FormContractorState.values.cGst === "undefined" ||
      FormContractorState.values.cGst === null
    ) {
      swal("Please select valid GST No");
      return false;
    } else if (
      FormContractorState.values.cCategory === "" ||
      typeof FormContractorState.values.cCategory === "undefined" ||
      FormContractorState.values.cCategory === null ||
      FormContractorState.values.cCategory === "0"
    ) {
      swal("Please select valid Category");
      return false;
    } else if (
      FormContractorState.values.pId === "" ||
      typeof FormContractorState.values.pId === "undefined" ||
      FormContractorState.values.pId === null ||
      FormContractorState.values.pId === "0"
    ) {
      swal("Please select valid Payment Type");
      return false;
    } else return true;
  };
  const handleSaveEntry = () => {
    setPdfLoading(true);
    //if (CheckData()) {
    setPdfLoading(true);
    const article = FormState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    if (type === "edit") {
      axios
        //.put(`${GetApiLink()}/api/soedit/${id}`, article, { headers })
        .put(`${GetApiLink()}api/sampleinward/edit`, article, { headers })
        .then((response) => {
          setPdfLoading(false);
          console.log("Sample Inward Update Save request :", response);
          if (response.data.validate === "false") {
            swal(response.data.message);
            return;
          } else if (response.data.error === "true") {
            swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
          } else {
            swal({
              icon: "success",
              title: "Update",
              text: response.data.message,
              type: "success",
            }).then(function () {
              history.push(`${process.env.PUBLIC_URL}/view/Master/CustomerMaster/view/${FormState.values.id}`);
            });
          }
        })
        .catch((error) => {
          setPdfLoading(false);
          console.log("Errors LOG", error);
        });
    } else if (type === "add") {
      axios
        .post(
          `${GetApiLink()}api/sampleinward/add`,
          { result: getResult, inwId: id, paraId: FormParaState.values.paraId, fName: FormParaState.values.fName },
          { headers }
        )
        .then((response) => {
          setPdfLoading(false);
          console.log("Sample Inward Save request :", response);
          if (response.data.validate === "false") {
            swal(response.data.message);
            return;
          } else if (response.data.error === "false") {
            swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
          } else {
            swal({
              icon: "sucess",
              title: "Done",
              text: response.data.message,
              type: "success",
            }).then(function () {
              setFinalResult(response.data.result[0]);
              toggleParaTestModel();
              window.location.reload();
            });
          }
        })
        .catch((error) => {
          setPdfLoading(false);
          console.log("Errors LOG", error);
        });
    }
    //}
    //}
  };

  useEffect(() => {
    if (type === "edit" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "view" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "add") {
      SoShow(id);
    }
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  useEffect(() => {
    const regexddmmyyyy = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;

    if (regexddmmyyyy.test(date)) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [date]);
  useEffect(() => {
    if (valid == false) {
      //swal('Please enter valid date format');
    }
  }, [valid]);
  const CheckData = () => {
    setPdfLoading(false);
    if (!FormState.values.formNo) {
      swal("Please valid form no");
      return false;
    } else if (!FormState.values.inwDate) {
      swal("Please valid inward date");
      return false;
    } else if (!FormState.values.revNo) {
      swal("Please valid Rev. No");
      return false;
    } else if (!FormState.values.revDate) {
      swal("Please valid Rev. Date");
      return false;
    } else if (!FormState.values.refNo) {
      swal("Please valid Ref. No");
      return false;
    } else if (!FormState.values.refDate) {
      swal("Please valid Ref. date");
      return false;
    } else if (!FormState.values.custId) {
      swal("Please valid Customer");
      return false;
    } else if (!FormState.values.agencyId) {
      swal("Please valid Agency");
      return false;
    } else if (!FormState.values.consId) {
      swal("Please valid Consultant");
      return false;
    } else if (!FormState.values.deliveryType) {
      swal("Please valid Invoice Delivery Option");
      return false;
      // }else if(!FormState.values.reportDetail){
      //   swal('Please valid Report Detail')
      //   return false;
    } else if (!FormState.values.testType) {
      swal("Please valid Test Type");
      return false;
    } else if (!FormState.values.tGrpId) {
      swal("Please valid Test Group");
      return false;
    } else if (!FormState.values.mId) {
      swal("Please valid Material Type");
      return false;
    } else if (!FormState.values.tId) {
      swal("Please valid Test Type");
      return false;
    } else if (!FormState.values.gradeId) {
      swal("Please valid Grade");
      return false;
    } else if (!FormState.values.testSpec) {
      swal("Please valid Test Specification");
      return false;
    } else if (!FormState.values.batchNo) {
      swal("Please valid Batch No");
      return false;
    } else if (!FormState.values.castDate) {
      swal("Please valid Casting date");
      return false;
    } else if (!FormState.values.work_name) {
      swal("Please valid Name of Work");
      return false;
    } else if (!FormState.values.totalSample) {
      swal("Please valid Total No. Of Sample");
      return false;
    } else if (!FormState.values.qty) {
      swal("Please valid Qty");
      return false;
    } else if (!FormState.values.quom) {
      swal("Please valid Qty UOM");
      return false;
    } else if (!FormState.values.totalSet) {
      swal("Please valid Total Sets");
      return false;
    } else if (!FormState.values.cSpec) {
      swal("Please valid Customer Specification");
      return false;
    } else if (!FormState.values.testerId) {
      swal("Please valid Tester");
      return false;
    } else if (!FormState.values.expTotalSample) {
      swal("Please valid Expected Total Sample");
      return false;
    } else if (!FormState.values.approvId) {
      swal("Please valid Approver");
      return false;
    } else if (!FormState.values.sampleCondition) {
      swal("Please valid Sample Condition");
      return false;
    } else if (!FormState.values.sysDate) {
      swal("Please valid Testing Date By System");
      return false;
    } else if (!FormState.values.testPerDays) {
      swal("Please valid Test performed days");
      return false;
    } else if (!FormState.values.testDate) {
      swal("Please valid Test date");
      return false;
    } else if (!FormState.values.rptDate) {
      swal("Please valid Report date");
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <OverlineTitle tag="span" className="preview-title-lg">
                {" "}
                Sample Inward Form
              </OverlineTitle>
              <form>
                <TabContent activeTab={activeTab}>
                  <Row className="gy-4">
                    <Col sm="12">
                      <table border={1} cellPadding={5} width={"100%"}>
                        <tr style={{ color: "#000" }}>
                          <td style={{ width: "25%" }}>Job No :</td>
                          <td style={{ width: "30%" }}>{FormState.values.jobNo}</td>
                          <td style={{ width: "25%" }}>Inward No :</td>
                          <td style={{ width: "30%" }}>{FormState.values.inwardNo}</td>
                        </tr>
                        <tr style={{ color: "#000" }}>
                          <td style={{ width: "25%" }}>PO/Ref. No :</td>
                          <td style={{ width: "30%" }}>{FormState.values.refNo}</td>
                          <td style={{ width: "25%" }}>Grade :</td>
                          <td style={{ width: "30%" }}>{FormState.values.grade}</td>
                        </tr>
                        <tr style={{ color: "#000" }}>
                          <td style={{ width: "25%" }}>Making / Batch No. / Type / Dia :</td>
                          <td style={{ width: "30%" }}>{FormState.values.batchNo}</td>
                          <td style={{ width: "25%" }}>Sample Condition :</td>
                          <td style={{ width: "30%" }}>{FormState.values.sampleCondition}</td>
                        </tr>
                        <tr style={{ color: "#000" }}>
                          <td style={{ width: "25%" }}>Sample Collected By :</td>
                          <td style={{ width: "20%" }}>{FormState.values.sampleBy}</td>
                          <td style={{ width: "25%" }}>Expected Date of Reporting :</td>
                          <td style={{ width: "20%" }}>{moment(FormState.values.rptDate).format("DD-MM-YYYY")}</td>
                        </tr>
                        <tr style={{ color: "#000" }}>
                          <td>Material Type :</td>
                          <td style={{ width: "100%" }} colspan="4">
                            {FormState.values.mName}
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row className="g-gs">
                    {FormState.values.paradetail &&
                      FormState.values.paradetail.map((item) => {
                        // let finalResult = 0;
                        // if(item.paraId == FormParaState.values.paraId && parseFloat(item.result) != 0){
                        //   finalResult = item.result;
                        // }else{
                        //   finalResult = getFinalResult.result;
                        // }
                        return (
                          <Col xxl="2" sm="4">
                            <Card className="card-bordered">
                              <CardBody className="card-inner">
                                {/* <CardTitle tag="h6">Material Type: {item.mName}</CardTitle> */}
                                <CardSubtitle tag="h6" style={{ color: "green", fontWeight: 800 }}>
                                  {item.paraName}
                                </CardSubtitle>
                                <CardText style={{ color: "red" }}>
                                  Result: {parseFloat(item.result).toFixed(4)}
                                </CardText>
                                <a
                                  className="btn-round btn-icon"
                                  color="success"
                                  size="sm"
                                  disabled={parseFloat(item.result) ? false : true}
                                  style={{ padding: 10, background: "#1ee0ac" }}
                                  onClick={(e) => {
                                    handleOpenParaForm(e, item.paraId);
                                  }}
                                >
                                  <Icon name="plus" />
                                </a>
                                <span style={{ padding: 10 }}></span>
                                <Button className="btn-round btn-icon" color="primary" size="sm">
                                  <Icon name="edit" />
                                </Button>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                  <Modal isOpen={modelParaForm} toggle={toggleParaTestModel}>
                    <ModalHeader
                      toggle={toggleParaTestModel}
                      close={
                        <button className="close" onClick={toggleParaTestModel}>
                          <Icon name="cross" />
                        </button>
                      }
                    >
                      Parameter Test
                    </ModalHeader>
                    <ModalBody>
                      <Row className="gy-4">
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="default-0" className="form-label">
                              Parameter Name:
                            </Label>
                            <div className="form-control-wrap">
                              <input
                                disabled
                                className="form-control form-control-sm"
                                type="hidden"
                                id="default-0"
                                placeholder=""
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                // onKeyDown={handleEnter}
                                value={FormParaState.values.paraId || ""}
                                name={"paraId"}
                              />
                              <input
                                disabled
                                className="form-control form-control-sm"
                                type="text"
                                id="default-0"
                                placeholder=""
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                // onKeyDown={handleEnter}
                                value={FormParaState.values.paraName || ""}
                                name={"paraName"}
                              />
                              <input
                                disabled
                                className="form-control form-control-sm"
                                type="hidden"
                                id="default-0"
                                placeholder=""
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                // onKeyDown={handleEnter}
                                value={FormParaState.values.fName || ""}
                                name={"fName"}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label htmlFor="default-0" className="form-label">
                              Formula Description:
                            </Label>
                            <div className="form-control-wrap">
                              <textarea
                                disabled
                                className="form-control form-control-sm"
                                type="text"
                                id="default-0"
                                placeholder=""
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                // onKeyDown={handleEnter}
                                value={
                                  !FormParaState.values.fDesc ? "" : FormParaState.values.fDesc.replace(/  +/g, " ")
                                }
                                name={"fDesc"}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        {FormState.values.paraInputDetail &&
                          FormState.values.paraInputDetail.map((d, i) => {
                            if (d.paraId == FormParaState.values.paraId) {
                              return (
                                <Col sm="2">
                                  <FormGroup>
                                    <Label htmlFor="default-0" className="form-label">
                                      {d.formula_name}
                                    </Label>
                                    <div className="form-control-wrap">
                                      <input
                                        className="form-control form-control-sm"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        onChange={(e) => {
                                          handleResult(e, d.formula_name, d.paraId);
                                        }}
                                        // onKeyDown={handleEnter}
                                        defaultValue={d.value}
                                        name={d.formula_name}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              );
                            }
                          })}
                      </Row>
                    </ModalBody>
                    <ModalFooter className="bg-light">
                      <FormGroup>
                        <Button
                          color="success"
                          name="Save"
                          onClick={(e) => {
                            handleSaveEntry(e);
                          }}
                        >
                          Save
                        </Button>
                        <Button color="primary" name="Save" onClick={toggleParaTestModel}>
                          Cancel
                        </Button>
                      </FormGroup>
                    </ModalFooter>
                  </Modal>
                </TabContent>

                <Backdrop className={classes.backdrop} open={PdfLoading}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </form>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default SampleInward;
