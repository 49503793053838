import React, { useState } from "react";
import { Redirect } from "react-router-dom";
//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

export const GetApiLink = (props) => {
  const api = process.env.NODE_ENV === "production" ? process.env.REACT_APP_LIVE : process.env.REACT_APP_LOCAL;
  return api;
};

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ") {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to set deadline for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);
  return date;
};

//Month Names
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//todays Date
export const todaysDate = new Date();

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

//shortens a long string
export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n) : str;
};

export const RedirectAs404 = ({ location }) => (
  <Redirect to={Object.assign({}, location, { state: { is404: true } })} />
);

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("userid");
  localStorage.removeItem("type");
  // localStorage.removeItem('sname');
};

// set the token and user from the session storage
export const setUserSession = (
  token,
  user,
  role,
  userid
  // sname
  //islyear
) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("userid", JSON.stringify(userid));
  localStorage.setItem("type", JSON.stringify(role));
};

export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  var app_version = localStorage.getItem("app_version");
  var app_version_chk = app_version && JSON.parse(app_version);
  // if (!app_version_chk || app_version_chk != process.env.REACT_APP_VERSION_NEW) {
  //   swal({
  //     icon: "warning",
  //     title: "Oops !!!",
  //     text: "Application version mismatch",
  //     type: "warning",
  //   }).then(function () {
  //     localStorage.clear();
  //     localStorage.setItem("app_version", JSON.stringify(process.env.REACT_APP_VERSION_NEW));
  //     window.location = "/auth/logout";
  //   });
  // }
  return sessionStorage.getItem("token") || null;
};

export const getUserid = () => {
  const userStr = localStorage.getItem("userid");
  if (userStr) return JSON.parse(userStr);
  else return null;
};
export const getUsertype = () => {
  const userStr = localStorage.getItem("type");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export function getHeaderDataConfig() {
  let reqdata = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  };
  return reqdata;
}

export const setfocus = (id) => {
  if (document.getElementById(id) !== null) {
    document.getElementById(id).focus();
  }
};

export const getRequestConfig = (props) => {
  let params = props.params ? props.params : null;

  let requestconfig = {
    headers: {
      // Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/javascript",
    },
    params,
  };
  return requestconfig;
};

// ContentType: `application / json`
export const getAuthToken = () => {
  const StrHeader = !getToken()
    ? null
    : {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/javascript",
        },
      };

  return StrHeader;
};

export function setbackpathpath(back = false) {
  console.log("back Call", back);
  const path = localStorage.getItem("path");
  if (back) {
    const BackPath = localStorage.getItem("path");
    if (back) {
      const bpath = BackPath.substring(1 + BackPath.lastIndexOf(">"), BackPath.length);
      localStorage.setItem("lpath", bpath);
    }
    const newpath = path.substring(0, path.lastIndexOf(" > "));
    localStorage.setItem("path", newpath);
  }
}
// export const getPathLabel = () => {
//   const path = localStorage.getItem("path");
//   return (
//     <div
//       style={{
//         color: colors.blueGrey[300],
//         backgroundColor: colors.indigo[50],
//         paddingLeft: "8px",
//       }}
//     >
//       <Typography style={{ fontSize: "10px", color: colors.blueGrey[600] }}>{path}</Typography>
//     </div>
//   );
// };
export const AuthProvider = (props) => {
  return !getToken() ? <Redirect to="/auth/login" /> : props.children;
};
export const AuthLoginProvider = (props) => {
  return getToken() ? <Redirect to="/" /> : props.children;
};
export const SetDateFormat = (date) => {
  return date.split("-").reverse().join("/");
};
export const SetDateFormat1 = (date) => {
  return date.split("/").reverse().join("-");
};
export const SetDateFormat2 = (date) => {
  return date.split("-").reverse().join("-");
};
export function getmaxsrno(arr, type) {
  return arr.reduce((max, obj) => {
    return obj[type] > max ? parseInt(obj[type]) : parseInt(max);
  }, 0);
}

//Sum OF The Given Array And Pass the Value Which You Want TO Sum
export function sumProperty(arr, type, con = "", val = "") {
  return arr.reduce((total, obj) => {
    if (typeof obj[type] === "string") {
      if (con !== "") {
        if (obj[con] === val) {
          return total + Number(obj[type]);
        } else {
          return total;
        }
      } else {
        return total + Number(obj[type]);
      }
    }
    if (con !== "") {
      if (obj[con] === val) {
        return total + obj[type];
      } else {
        return total;
      }
    } else {
      return total + obj[type];
    }
  }, 0);
}

// export const numberFormat = (value) => {
//   let x = "";
//   if (typeof value != "undefined" && value != null) {
//     let Valuex = value.toString().replace(/,/g, "");
//     x = RsnumberFormat(Valuex);
//   }
//   return x.toString().replace(/₹/g, "");
// };

export function toProperCase(str) {
  if (typeof str !== "undefined") {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
export const setFormatDate = (date) => {
  var res = date.slice(0, 4);
  var res1 = date.slice(4, 6);
  var res2 = date.slice(-2);

  return `${res}-${res1}-${res2}`;
};

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
export function handleOrderEnter(event, search = false) {
  if (event.keyCode === 13) {
    const form = event.target.form;
    if (
      form !== null &&
      typeof form !== "undefined" &&
      event.target !== "" &&
      typeof event.target !== "undefined" &&
      event.target !== null
    ) {
      const index = Array.prototype.indexOf.call(form, event.target);
      // if (form.elements.length > index + 1) {
      //   const NextIndex =
      //     form.elements[index + 1].IconButton ||
      //     form.elements[index + 1].disabled
      //       ? index + 2
      //       : index + 1;
      if (form.elements.length > index + 1) {
        const NextIndex = form.elements[index + 1].disabled
          ? event.target.type == "button"
            ? index + 1
            : index + 2
          : index + 1;
        if (form.elements.length > NextIndex) {
          form.elements[NextIndex].focus();
        }
      }
      event.preventDefault();
    }
  }
}

//current Date
export function getCurrentDate(separator = "-") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  // return `${year}${separator}
  // ${month < 10 ? `0${month}` : `${month}`}
  // ${separator}${date < 10 ? `0${date}` : `${date}`}`;
  return `${year}${separator}
  ${month < 10 ? `0${month}` : `${month}`}
  ${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

export function roundAmt(amount) {
  let num = `'${parseFloat(amount).toFixed(2)}'`;
  let number = num.substring(1);
  let n2 = number.substring(0, num.length - 1);
  n2 = n2.substring(0, n2.length - 1);
  let final = n2;
  let n = final.substring(0, final.length - 3);
  let d = final.substring(final.length - 2);
  let f = 0;
  if (d >= "50" || d >= "5") {
    f = `${parseInt(n) + 1}`;
  } else {
    f = `${n}`;
  }
  return parseFloat(f).toFixed(2);
}
export function str2bytes(str) {
  var bytes = new Uint8Array(str.length);
  for (var i = 0; i < str.length; i++) {
    bytes[i] = str.charCodeAt(i);
  }
  return bytes;
}