import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";


import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  Button,
} from "../../components/Component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import ReactInputDateMask from "react-input-date-mask";
import { GetApiLink, getRequestConfig, getUser } from "../../utils/Utils";
import swal from "sweetalert";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));
const ConsultantMaster = (props) => {
  const { match, history } = props;
  const { id, type } = match.params;
  const classes = useStyles();
const [PdfLoading, setPdfLoading] = useState(false);
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [CityList, setCityList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [PaymentList, setPaymentList] = useState([]);
  const [FormState, setFormState] = useState({
    values: {},
  });
  const [FormCityState, setFormCityState] = useState({
    values: {},
  });
  const [FormStateState, setFormStateState] = useState({
    values: {},
  });
  const [FormCountryState, setFormCountryState] = useState({
    values: {},
  });
  const [modalForm, setModalForm] = useState(false);
  const toggleForm = () => setModalForm(!modalForm);

  const [modalStateForm, setModalStateForm] = useState(false);
  const toggleStateForm = () => setModalStateForm(!modalStateForm);

  const [modalCountryForm, setModalCountryForm] = useState(false);
  const toggleCountryForm = () => setModalCountryForm(!modalCountryForm);

  function fetchCityMasterList() {
    axios
      .get(`${GetApiLink()}api/citymasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" City List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setCityList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" City List Get Error: ", error);
      });
  }

  function fetchStateMasterList() {
    axios
      .get(`${GetApiLink()}api/statemasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" State List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setStateList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" State List Get Error: ", error);
      });
  }

  function fetchCountryList() {
    axios
      .get(`${GetApiLink()}api/countrymasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Country List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setCountryList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Country List Get Error: ", error);
      });
  }

  function fetchPaymentList() {
    axios
      .get(`${GetApiLink()}api/paymentmasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Payment List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setPaymentList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Payment List Get Error: ", error);
      });
  }

  const handleCitySaveEntry = () => {
    setPdfLoading(true);

    //setPdfLoading(true);
    const article = FormCityState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    axios
      .post(`${GetApiLink()}api/citymaster/add`, article, { headers })
      .then((response) => {
        setPdfLoading(false);
        console.log("City Save request :", response);
        if (response.data.validate === "false") {
          swal(response.data.message);
          return;
        } else if (response.data.error === "false") {
          swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
        } else {
          swal({
            icon: "success",
            title: "Success",
            text: response.data.message,
            type: "success",
          }).then(function () {
            fetchCityMasterList();
            setFormCityState((FormCityState) => ({
              ...FormCityState,
              values: {
                ...FormCityState.values,
                CityName: "",
              },
            }));
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ["cCity"]: response.data.data.id,
              },
            }));
            toggleForm();
          });
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Errors LOG", error);
      });

    // }
  };

  const handleStateSaveEntry = () => {
    setPdfLoading(true);

    //setPdfLoading(true);
    const article = FormStateState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    axios
      .post(`${GetApiLink()}api/statemaster/add`, article, { headers })
      .then((response) => {
        setPdfLoading(false);
        console.log("State Save request :", response);
        if (response.data.validate === "false") {
          swal(response.data.message);
          return;
        } else if (response.data.error === "false") {
          swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
        } else {
          swal({
            icon: "success",
            title: "Success",
            text: response.data.message,
            type: "success",
          }).then(function () {
            fetchStateMasterList();
            setFormStateState((FormStateState) => ({
              ...FormStateState,
              values: {
                ...FormStateState.values,
                StateName: "",
              },
            }));
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ["cState"]: response.data.data.id,
              },
            }));
            toggleStateForm();
          });
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Errors LOG", error);
      });

    // }
  };

  const handleCountrySaveEntry = () => {
    setPdfLoading(true);

    //setPdfLoading(true);
    const article = FormCountryState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    axios
      .post(`${GetApiLink()}api/countrymaster/add`, article, { headers })
      .then((response) => {
        setPdfLoading(false);
        console.log("Country Save request :", response);
        if (response.data.validate === "false") {
          swal(response.data.message);
          return;
        } else if (response.data.error === "false") {
          swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
        } else {
          swal({
            icon: "success",
            title: "Success",
            text: response.data.message,
            type: "success",
          }).then(function () {
            fetchCountryList();
            setFormCountryState((FormCountryState) => ({
              ...FormCountryState,
              values: {
                ...FormCountryState.values,
                CountryName: "",
              },
            }));
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ["cCountry"]: response.data.data.id,
              },
            }));
            toggleCountryForm();
          });
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Errors LOG", error);
      });

    // }
  };
  const handleSaveEntry = () => {
    setPdfLoading(true);
    if (CheckDate()) {
      setPdfLoading(true);
      const article = FormState.values;
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };

      if (type === "edit") {
        axios
          //.put(`${GetApiLink()}/api/soedit/${id}`, article, { headers })
          .put(`${GetApiLink()}api/customermaster/edit`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Consultant Update Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "true") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal({
                icon: "success",
                title: "Update",
                text: response.data.message,
                type: "success",
              }).then(function () {
                history.push(`${process.env.PUBLIC_URL}/view/Master/ConsultantMaster/view/${FormState.values.id}`);
              });
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      } else if (type === "add") {
        axios
          .post(`${GetApiLink()}api/customermaster/add`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Consultant Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "false") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal(response.data.message, "", "success");
              history.push(`${process.env.PUBLIC_URL}/view/Master/ConsultantMaster/add/0`);
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      }
      // }
    }
  };
  const handleDeleteEntry = () => {
    setPdfLoading(true);
    const article = FormState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    axios
      .post(`${GetApiLink()}api/customermaster/delete`, article, { headers }).then((response) => {
            setPdfLoading(false);
            console.log("Consultant Delete request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "true") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal({
                icon: "success",
                title: "Update",
                text: response.data.message,
                type: "success",
              }).then(function () {
                history.push(`${process.env.PUBLIC_URL}/view/Master/ConsultantMasterList`);
              });
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
  };
  const handleDelete = (event) =>{
    event.preventDefault();
    handleDeleteEntry(FormState.values.id);
  }
  const CheckDate = () => {
    setPdfLoading(false);
    if (
      FormState.values.cCode === "" ||
      typeof FormState.values.cCode === "undefined" ||
      FormState.values.cCode === null
    ) {
      swal("Please select valid code");
      return false;
    } else if (
      FormState.values.cName === "" ||
      typeof FormState.values.cName === "undefined" ||
      FormState.values.cName === null
    ) {
      swal("Please select valid Name");
      return false;
    } else if (
      FormState.values.cType === "" ||
      typeof FormState.values.cType === "undefined" ||
      FormState.values.cType === null ||
      FormState.values.cType === "0"
    ) {
      swal("Please select valid Consultant type");
      return false;
    } else if (
      FormState.values.cNumberOne === "" ||
      typeof FormState.values.cNumberOne === "undefined" ||
      FormState.values.cNumberOne === null
    ) {
      swal("Please select valid Contact Number");
      return false;
    } else if (
      FormState.values.cAddressOne === "" ||
      typeof FormState.values.cAddressOne === "undefined" ||
      FormState.values.cAddressOne === null
    ) {
      swal("Please select valid Address");
      return false;
    } else if (
      FormState.values.cPanCard === "" ||
      typeof FormState.values.cPanCard === "undefined" ||
      FormState.values.cPanCard === null
    ) {
      swal("Please select valid Pancard");
      return false;
    } else if (
      FormState.values.cGst === "" ||
      typeof FormState.values.cGst === "undefined" ||
      FormState.values.cGst === null
    ) {
      swal("Please select valid GST No");
      return false;
    } else if (
      FormState.values.cCategory === "" ||
      typeof FormState.values.cCategory === "undefined" ||
      FormState.values.cCategory === null ||
      FormState.values.cCategory === "0"
    ) {
      swal("Please select valid Category");
      return false;
    } else if (
      FormState.values.pId === "" ||
      typeof FormState.values.pId === "undefined" ||
      FormState.values.pId === null ||
      FormState.values.pId === "0"
    ) {
      swal("Please select valid Payment Type");
      return false;
    } else return true;
  };

  const SoShow = (testId) => {
    setPdfLoading(true);
    // if (id === "") {
    //   //setPdfLoading(false);
    //   history.push(`${process.env.PUBLIC_URL}/view/Master/ConsultantMasterList`);
    // }
    axios
      .get(
        `${GetApiLink()}api/customermaster/${testId}`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Consultant master Data Show Get: ", response);
        //console.log('A',response.data.response.error);
        if (typeof response.data.error != "undefined" && response.data.error == "true") {
          swal({
            icon: "error",
            title: "Opps!!!",
            text: response.data.message,
            type: "error",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Master/ConsultantMasterList`);
          });
        } else {
          // if (response.data.response.isauth == "_AUTHFAILED") {
          //   history.push("/auth/logout");
          // } else {
          if (type === "add") {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                id: response.data.maxId,
              },
            }));
          } else {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ...response.data.data,
              },
            }));
          }
          //}
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Consultant Master Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  };
  let keysPressed = {};
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  const handleChange = (event) => {
    event.persist();
    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleCityChange = (event) => {
    event.persist();
    setFormCityState((FormCityState) => ({
      ...FormCityState,
      values: {
        ...FormCityState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleStateChange = (event) => {
    event.persist();
    setFormStateState((FormStateState) => ({
      ...FormStateState,
      values: {
        ...FormStateState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleCountryChange = (event) => {
    event.persist();
    setFormCountryState((FormCountryState) => ({
      ...FormCountryState,
      values: {
        ...FormCountryState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };
  function handleEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    handleSaveEntry();
    if (type == "view") {
      history.push(`${process.env.PUBLIC_URL}/view/Master/ConsultantMaster/edit/${FormState.values.id}`);
    }
  };
  const handleCancel = (event) => {
    event.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/view/Master/ConsultantMasterList`);
  };

  const handleCitySubmit = (event) => {
    event.preventDefault();
    handleCitySaveEntry();
  };

  const handleStateSubmit = (event) => {
    event.preventDefault();
    handleStateSaveEntry();
  };

  const handleCountrySubmit = (event) => {
    event.preventDefault();
    handleCountrySaveEntry();
  };
  useEffect(() => {
    fetchCityMasterList();
    fetchCountryList();
    fetchStateMasterList();
    fetchPaymentList();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  useEffect(() => {
    if (type === "edit" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "view" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "add") {
      SoShow("0");
    }
  }, [type]);

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-md">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <OverlineTitle tag="span" className="preview-title-lg">
                {" "}
                Consultant Master
              </OverlineTitle>
              <form>
                <Row className="gy-4">
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        ID:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.id || ""}
                          name={"id"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Consultant Code:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cCode || ""}
                          name={"cCode"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Consultant Type:
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cType || ""}
                          name={"cType"}
                        >
                          <option value="0">Select</option>
                          <option value="GOV">Goverment</option>
                          <option values="SGOV">Semi-Goverment</option>
                          <option value="GEN">General</option>
                          <option value="OTH">Others</option>
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Consultant Name:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cName || ""}
                          name={"cName"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Contact Person:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cPerson || ""}
                          name={"cPerson"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Contact Number:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cNumberOne || ""}
                          name="cNumberOne"
                        />
                        {/* <input className="form-control form-control-sm" type="text" id="default-0" placeholder="" /> */}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Alternative Number:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cNumberTwo || ""}
                          name="cNumberTwo"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Email:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cEmail || ""}
                          name="cEmail"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Registered Office Address:
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          cols={2}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cAddressOne || ""}
                          name="cAddressOne"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  {/* <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Side Address:
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          cols={2}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cAddressTwo || ""}
                          name="cAddressTwo"
                        />
                      </div>
                    </FormGroup>
                  </Col> */}
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        City:
                        <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            onClick={toggleForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div>
                      </Label>

                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cCity || ""}
                          name="cCity"
                        >
                          <option>Select</option>
                          {CityList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.CityName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        State:
                        <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            onClick={toggleStateForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div>
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cState || ""}
                          name="cState"
                        >
                          <option>Select</option>
                          {StateList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.StateName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Country:
                        <div style={{ width: "10%", float: "right", marginTop: "0px" }}>
                          <a
                            className="btn-round btn-icon"
                            color="success"
                            size="sm"
                            style={{ padding: "5px", background: "#1ee0ac" }}
                            onClick={toggleCountryForm}
                          >
                            <Icon name="plus" />
                          </a>
                        </div>
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cCountry || ""}
                          name="cCountry"
                        >
                          <option>Select</option>
                          {CountryList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.CountryName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        PAN No:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cPanCard || ""}
                          name="cPanCard"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        GST No:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.cGst || ""}
                          name="cGst"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Payment Term:
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.pId || ""}
                          name="pId"
                        >
                          <option>Select</option>
                          {PaymentList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.pName}
                              </option>
                            );
                          })}
                        </select>
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="hidden"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={(FormState.values.cCategory = "CON")}
                          name="cCategory"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="2">
                    <Button
                      color="success"
                      name="Save"
                      style={{ display: type == "view" ? "none" : "" }}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      color="success"
                      name="Save"
                      style={{ display: type == "view" ? "" : "none" }}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Modify
                    </Button>
                  </Col>
                  <Col sm="2">
                    <Button color="danger" name="Delete"
                      onClick={e=>{
                        handleDelete(e)
                      }}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col sm="2">
                    <Button
                      color="primary"
                      name="Cancel"
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </form>
              
<Backdrop className={classes.backdrop} open={PdfLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>
        </Card>
      </Content>
      <Modal isOpen={modalForm} toggle={toggleForm}>
        <ModalHeader
          toggle={toggleForm}
          close={
            <button className="close" onClick={toggleForm}>
              <Icon name="cross" />
            </button>
          }
        >
          City Master
        </ModalHeader>
        <ModalBody>
          <Row className="gy-4">
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  City Name:
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    id="default-0"
                    placeholder=""
                    value={FormCityState.values.CityName}
                    name={"CityName"}
                    onChange={(e) => {
                      handleCityChange(e);
                    }}
                    onKeyDown={handleEnter}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button
              color="success"
              name="Save"
              onClick={(e) => {
                handleCitySubmit(e);
              }}
            >
              Save
            </Button>
            <Button color="primary" name="Cancel" onClick={toggleForm}>
              Cancel
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalStateForm} toggle={toggleStateForm}>
        <ModalHeader
          toggle={toggleStateForm}
          close={
            <button className="close" onClick={toggleStateForm}>
              <Icon name="cross" />
            </button>
          }
        >
          State Master
        </ModalHeader>
        <ModalBody>
          <Row className="gy-4">
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  State Name:
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    id="default-0"
                    placeholder=""
                    value={FormStateState.values.StateName}
                    name={"StateName"}
                    onChange={(e) => {
                      handleStateChange(e);
                    }}
                    onKeyDown={handleEnter}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button
              color="success"
              name="Save"
              onClick={(e) => {
                handleStateSubmit(e);
              }}
            >
              Save
            </Button>
            <Button color="primary" name="Cancel" onClick={toggleStateForm}>
              Cancel
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalCountryForm} toggle={toggleCountryForm}>
        <ModalHeader
          toggle={toggleCountryForm}
          close={
            <button className="close" onClick={toggleCountryForm}>
              <Icon name="cross" />
            </button>
          }
        >
          Country Master
        </ModalHeader>
        <ModalBody>
          <Row className="gy-4">
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Country Name:
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    id="default-0"
                    placeholder=""
                    value={FormCountryState.values.CountryName}
                    name={"CountryName"}
                    onChange={(e) => {
                      handleCountryChange(e);
                    }}
                    onKeyDown={handleEnter}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="bg-light">
          <FormGroup>
            <Button
              color="success"
              name="Save"
              onClick={(e) => {
                handleCountrySubmit(e);
              }}
            >
              Save
            </Button>
            <Button color="primary" name="Cancel" onClick={toggleCountryForm}>
              Cancel
            </Button>
          </FormGroup>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ConsultantMaster;
