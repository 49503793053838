import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  Button,
} from "../../components/Component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import ReactInputDateMask from "react-input-date-mask";
import { GetApiLink, getRequestConfig } from "../../utils/Utils";
import swal from "sweetalert";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));
const MaterialTestMaster = (props) => {
  const { match, history } = props;
  const { id, type } = match.params;
  const classes = useStyles();
const [PdfLoading, setPdfLoading] = useState(false);
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [TestGroupList, setTestGroupList] = useState([]);
  const [FormState, setFormState] = useState({
    values: {},
  });

  function fetchTestGroupList() {
    axios
      .get(`${GetApiLink()}api/testgroups`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Test Group List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setTestGroupList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Test Group List Get Error: ", error);
      });
  }

  let keysPressed = {};
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  const SoShow = (testId) => {
    setPdfLoading(true);
    // if (id === "") {
    //   //setPdfLoading(false);
    //   history.push(`${process.env.PUBLIC_URL}/view/Master/MaterialTestMasterList`);
    // }
    axios
      .get(
        `${GetApiLink()}api/material/${testId}`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Material Data Show Get: ", response);
        //console.log('A',response.data.response.error);
        if (typeof response.data.error != "undefined" && response.data.error == "true") {
          swal({
            icon: "error",
            title: "Opps!!!",
            text: response.data.message,
            type: "error",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Master/MaterialTestMasterList`);
          });
        } else {
          // if (response.data.response.isauth == "_AUTHFAILED") {
          //   history.push("/auth/logout");
          // } else {
          if (type === "add") {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                id: response.data.maxId,
              },
            }));
          } else {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                id: response.data.data.id,
                tGrpId: response.data.data.tGrpId,
                mCode: response.data.data.mCode,
                mName: response.data.data.mName,
                mDetail: response.data.data.mDetail,
                IsOffline: response.data.data.IsOffline,
                fNo:response.data.data.fNo,
                fDate:response.data.data.fDate
              },
            }));
          }
          //}
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Material Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  };
  const handleSaveEntry = () => {
    setPdfLoading(true);
    if (CheckDate()) {
      setPdfLoading(true);
      const article = FormState.values;
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };

      if (type === "edit") {
        axios
          //.put(`${GetApiLink()}/api/soedit/${id}`, article, { headers })
          .put(`${GetApiLink()}api/material/edit`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Material Update Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "true") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal({
                icon: "success",
                title: "Update",
                text: response.data.message,
                type: "success",
              }).then(function () {
                history.push(`${process.env.PUBLIC_URL}/view/Master/MaterialTestMaster/view/${FormState.values.id}`);
              });
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      } else if (type === "add") {
        axios
          .post(`${GetApiLink()}api/material/add`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Material Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "false") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal("Saved successfully.", "", "success");
              history.push(`${process.env.PUBLIC_URL}/view/Master/MaterialTestMaster/add/0`);
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      }
      // }
    }
  };
  const handleDeleteEntry = () => {
    setPdfLoading(true);
    const article = FormState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    axios
      .post(`${GetApiLink()}api/material/delete`, article, { headers }).then((response) => {
            setPdfLoading(false);
            console.log("Material Delete request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "true") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal({
                icon: "success",
                title: "Update",
                text: response.data.message,
                type: "success",
              }).then(function () {
                history.push(`${process.env.PUBLIC_URL}/view/Master/MaterialTestMasterList`);
              });
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
  };
  const handleDelete = (event) =>{
    event.preventDefault();
    handleDeleteEntry(FormState.values.id);
  }
  const CheckDate = () => {
    setPdfLoading(false);
    if (
      FormState.values.tGrpId === "" ||
      typeof FormState.values.tGrpId === "undefined" ||
      FormState.values.tGrpId === null
    ) {
      swal("Please select valid Test Group");
      return false;
    } else if (
      FormState.values.mCode === "" ||
      typeof FormState.values.mCode === "undefined" ||
      FormState.values.mCode === null
    ) {
      swal("Please select valid code");
      return false;
    } else if (
      FormState.values.mName === "" ||
      typeof FormState.values.mName === "undefined" ||
      FormState.values.mName === null
    ) {
      swal("Please select valid name");
      return false;
    } else if (
      FormState.values.mDetail === "" ||
      typeof FormState.values.mDetail === "undefined" ||
      FormState.values.mDetail === null
    ) {
      swal("Please select detail");
      return false;
    } else return true;
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        [event.target.name]: event.target.type === "checkbox" ? (event.target.checked ? "1" : "0") : event.target.value,
      },
    }));
  };
  function handleEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    handleSaveEntry();
    if (type == "view") {
      history.push(`${process.env.PUBLIC_URL}/view/Master/MaterialTestMaster/edit/${FormState.values.id}`);
    }
  };
  const handleCancel = (event) => {
    event.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/view/Master/MaterialTestMasterList`);
  };
  useEffect(() => {
    fetchTestGroupList();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  useEffect(() => {
    if (type === "edit" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "view" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "add") {
      SoShow("0");
    }
  }, [type]);
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-md">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <OverlineTitle tag="span" className="preview-title-lg">
                {" "}
                Material Test Master
              </OverlineTitle>
              <form>
                <Row className="gy-4">
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Material ID:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.id || ""}
                          name={"id"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Test Group:
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.tGrpId || ""}
                          name={"tGrpId"}
                        >
                          <option value="0"> Select Material Name</option>
                          {TestGroupList.map((t) => {
                            return (
                              <option value={t.id} key={t.id}>
                                {t.test_group_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Material Code:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.mCode || ""}
                          name={"mCode"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Material Name:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.mName || ""}
                          name={"mName"}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        F.No.:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.fNo || ""}
                          name={"fNo"}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        F.Date:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="date"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.fDate || ""}
                          name={"fDate"}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="preview-block">
                        <span className="preview-title overline-title"></span>
                        <div className="g-3 align-center flex-wrap">
                          <div className="g">
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input form-control"
                                id="customCheck10"
                                value={FormState.values.IsOffline || ""}
                                name={"IsOffline"}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                checked={FormState.values.IsOffline == "0" ? false : true}
                              />
                              <label className="custom-control-label" htmlFor="customCheck10">
                                Offline
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Detail:
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          cols={2}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.mDetail || ""}
                          name="mDetail"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="gy-4">
                  <Col sm="2">
                    <Button
                      color="success"
                      name="Save"
                      style={{ display: type == "view" ? "none" : "" }}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      color="success"
                      name="Save"
                      style={{ display: type == "view" ? "" : "none" }}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Modify
                    </Button>
                  </Col>
                  <Col sm="2">
                    <Button color="danger" name="Delete"
                      onClick={e=>{
                        handleDelete(e)
                      }}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col sm="2">
                    <Button
                      color="primary"
                      name="Cancel"
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </form>
              <Backdrop className={classes.backdrop} open={PdfLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default MaterialTestMaster;
