import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  ReactDataTable,
} from "../../components/Component";

import DataTable from "react-data-table-component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import { GetApiLink, getRequestConfig } from "../../utils/Utils";
import axios from "axios";
const CompanyMasterList= () => {
  const [file, setFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [CompanyMasterList, setCompanyMasterList] = useState([]);
  const history = useHistory();
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  function fetchCompanyMasterList() {
    axios
      .get(`${GetApiLink()}api/companymasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Company Master List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setCompanyMasterList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Company Master List Error: ", error);
      });
  }
  useEffect(() => {
    fetchCompanyMasterList();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);
  const handleButtonClick = (e, row) => {
    alert(row);
  };
  // const DataTableData = [
  //   {
  //     id: 1,
  //     agencyname: "Viral Patel",
  //     contactperson: "Viral Patel",
  //     contactnumber: "9601120820",
  //     email: "viralpatel977@gmail.com",
  //   },
  // ];
  const onRowClick = (e, v) => {
    history.push(`${process.env.PUBLIC_URL}/view/Master/CompanyMaster/edit/${v}`);
  };
  const AddNewData = (e) => {
    history.push(`${process.env.PUBLIC_URL}/view/Master/CompanyMaster/add/0`);
  };
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <div className={`code-block code-block-clean }`}>
                <OverlineTitle className="title">{"Customer Master List"}</OverlineTitle>
                <Button
                  color="primary"
                  onClick={(e) => {
                    AddNewData(e);
                  }}
                  size="sm"
                  className="clipboard-init"
                  style={{ backgroundColor: "#854fff" }}
                >
                  {"Add New"}
                </Button>
              </div>
              <Block size="lg">
                <PreviewCard>
                  {/* <ReactDataTable
                    data={DataTableData}
                    columns={dataTableColumns}
                    expandableRows
                    pagination
                    actions
                    pointerOnHover={true}
                    highlightOnHover={true}
                  /> */}
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Company Name</th>
                        <th>Contact No</th>
                        <th>Email</th>
                        <th>GST No</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CompanyMasterList.map((d) => {
                        return (
                          <tr>
                            <td>{d.id}</td>
                            <td>{d.comp_name}</td>
                            <td>{d.contactOne}</td>
                            <td>{d.email}</td>
                            <td>{d.gstNo}</td>
                            <td>
                              <Button
                                color="primary"
                                size="sm"
                                className="clipboard-init"
                                style={{ backgroundColor: "#854fff" }}
                                onClick={(e) => {
                                  onRowClick(e, d.id, "edit");
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </PreviewCard>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default CompanyMasterList;
