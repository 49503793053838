import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  PreviewCard,
  CodeBlock,
  OverlineTitle,
  OutlinedInput,
  Icon,
  Button,
} from "../../components/Component";
import { defaultOptions, groupedData, colourData } from "../SelectData";
import { RSelect } from "../../components/Component";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import ReactInputDateMask from "react-input-date-mask";
import { GetApiLink, getRequestConfig, getUser } from "../../utils/Utils";
import swal from "sweetalert";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));
const EmployeeMaster = (props) => {
  const { match, history } = props;
  const { id, type } = match.params;
  const classes = useStyles();
  const [PdfLoading, setPdfLoading] = useState(false);
  const [aadharFile, setAadharFile] = useState(null);
  const [qualificationFile, setQualificationFile] = useState("");
  const animatedComponents = makeAnimated();
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [CityList, setCityList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  const [PaymentList, setPaymentList] = useState([]);
  const [FormState, setFormState] = useState({
    values: {},
  });
  const [FormCityState, setFormCityState] = useState({
    values: {},
  });
  const [FormStateState, setFormStateState] = useState({
    values: {},
  });
  const [FormCountryState, setFormCountryState] = useState({
    values: {},
  });
  const [modalForm, setModalForm] = useState(false);
  const [modalStateForm, setModalStateForm] = useState(false);
  const [modalCountryForm, setModalCountryForm] = useState(false);
  const toggleForm = () => setModalForm(!modalForm);
  const toggleStateForm = () => setModalForm(!modalStateForm);
  const toggleCountryForm = () => setModalForm(!modalCountryForm);
  let keysPressed = {};
  function fetchCityMasterList() {
    axios
      .get(`${GetApiLink()}api/citymasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" City List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setCityList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" City List Get Error: ", error);
      });
  }

  function fetchStateMasterList() {
    axios
      .get(`${GetApiLink()}api/statemasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" State List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setStateList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" State List Get Error: ", error);
      });
  }

  function fetchCountryList() {
    axios
      .get(`${GetApiLink()}api/countrymasterlist`, getRequestConfig({ params: null }))
      .then((response) => {
        console.log(" Country List Get: ", response);
        // if (response.data.response.isauth == "_AUTHFAILED") {
        //   history.push("/auth/logout");
        // } else {
        console.log(response.data);
        setCountryList(response.data);
        //}
      })
      .catch((error) => {
        //fetchYear(PrpCode);
        console.log(" Country List Get Error: ", error);
      });
  }
  const handleCitySaveEntry = () => {
    setPdfLoading(true);

    //setPdfLoading(true);
    const article = FormCityState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    axios
      .post(`${GetApiLink()}api/citymaster/add`, article, { headers })
      .then((response) => {
        setPdfLoading(false);
        console.log("City Save request :", response);
        if (response.data.validate === "false") {
          swal(response.data.message);
          return;
        } else if (response.data.error === "false") {
          swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
        } else {
          swal({
            icon: "success",
            title: "Success",
            text: response.data.message,
            type: "success",
          }).then(function () {
            fetchCityMasterList();
            setFormCityState((FormCityState) => ({
              ...FormCityState,
              values: {
                ...FormCityState.values,
                CityName: "",
              },
            }));
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ["cCity"]: response.data.data.id,
              },
            }));
            toggleForm();
          });
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Errors LOG", error);
      });

    // }
  };

  const handleStateSaveEntry = () => {
    setPdfLoading(true);

    //setPdfLoading(true);
    const article = FormStateState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    axios
      .post(`${GetApiLink()}api/statemaster/add`, article, { headers })
      .then((response) => {
        setPdfLoading(false);
        console.log("State Save request :", response);
        if (response.data.validate === "false") {
          swal(response.data.message);
          return;
        } else if (response.data.error === "false") {
          swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
        } else {
          swal({
            icon: "success",
            title: "Success",
            text: response.data.message,
            type: "success",
          }).then(function () {
            fetchStateMasterList();
            setFormStateState((FormStateState) => ({
              ...FormStateState,
              values: {
                ...FormStateState.values,
                StateName: "",
              },
            }));
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ["cState"]: response.data.data.id,
              },
            }));
            toggleStateForm();
          });
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Errors LOG", error);
      });

    // }
  };

  const handleCountrySaveEntry = () => {
    setPdfLoading(true);

    //setPdfLoading(true);
    const article = FormCountryState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };

    axios
      .post(`${GetApiLink()}api/countrymaster/add`, article, { headers })
      .then((response) => {
        setPdfLoading(false);
        console.log("Country Save request :", response);
        if (response.data.validate === "false") {
          swal(response.data.message);
          return;
        } else if (response.data.error === "false") {
          swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
        } else {
          swal({
            icon: "success",
            title: "Success",
            text: response.data.message,
            type: "success",
          }).then(function () {
            fetchCountryList();
            setFormCountryState((FormCountryState) => ({
              ...FormCountryState,
              values: {
                ...FormCountryState.values,
                CountryName: "",
              },
            }));
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ["cCountry"]: response.data.data.id,
              },
            }));
            toggleCountryForm();
          });
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Errors LOG", error);
      });

    // }
  };
  const handleSaveEntry = () => {
    setPdfLoading(true);
    if (CheckDate()) {
      //setPdfLoading(true);
      const article = FormState.values;
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };

      if (type === "edit") {
        axios
          //.put(`${GetApiLink()}/api/soedit/${id}`, article, { headers })
          .put(`${GetApiLink()}api/employeemaster/edit`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Employee Update Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "true") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal({
                icon: "success",
                title: "Update",
                text: response.data.message,
                type: "success",
              }).then(function () {
                history.push(`${process.env.PUBLIC_URL}/view/Master/EmployeeMaster/view/${FormState.values.id}`);
              });
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      } else if (type === "add") {
        axios
          .post(`${GetApiLink()}api/employeemaster/add`, article, { headers })
          .then((response) => {
            setPdfLoading(false);
            console.log("Employee Save request :", response);
            if (response.data.validate === "false") {
              swal(response.data.message);
              return;
            } else if (response.data.error === "false") {
              swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
            } else {
              swal(response.data.message, "", "success");
              history.push(`${process.env.PUBLIC_URL}/view/Master/EmployeeMaster/add/0`);
            }
          })
          .catch((error) => {
            setPdfLoading(false);
            console.log("Errors LOG", error);
          });
      }
      // }
    }
  };

  const handleDeleteEntry = () => {
    setPdfLoading(true);
    const article = FormState.values;
    const headers = {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    };
    axios
      .post(`${GetApiLink()}api/employeemaster/delete`, article, { headers })
      .then((response) => {
        setPdfLoading(false);
        console.log("EmployeeDelete request :", response);
        if (response.data.validate === "false") {
          swal(response.data.message);
          return;
        } else if (response.data.error === "true") {
          swal("Error", `Opps Something went wrong!!! ${response.data.message}`, "error");
        } else {
          swal({
            icon: "success",
            title: "Update",
            text: response.data.message,
            type: "success",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Master/EmployeeMasterList`);
          });
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Errors LOG", error);
      });
  };
  const CheckDate = () => {
    setPdfLoading(false);
    if (!FormState.values.eCode) {
      swal("Please select valid Category");
      return false;
    } else if (!FormState.values.eName) {
      swal("Please select valid Name");
      return false;
    } else if (!FormState.values.eCategory) {
      swal("Please select valid Employee type");
      return false;
    } else if (!FormState.values.contactOne) {
      swal("Please select valid Contact Number");
      return false;
    } else if (!FormState.values.address) {
      swal("Please select valid Address");
      return false;
      // } else if (
      //   FormState.values.cPanCard === "" ||
      //   typeof FormState.values.cPanCard === "undefined" ||
      //   FormState.values.cPanCard === null
      // ) {
      //   swal("Please select valid Pancard");
      //   return false;
      // } else if (
      //   FormState.values.cGst === "" ||
      //   typeof FormState.values.cGst === "undefined" ||
      //   FormState.values.cGst === null
      // ) {
      //   swal("Please select valid GST No");
      //   return false;
      // } else if (
      //   FormState.values.cCategory === "" ||
      //   typeof FormState.values.cCategory === "undefined" ||
      //   FormState.values.cCategory === null ||
      //   FormState.values.cCategory === "0"
      // ) {
      //   swal("Please select valid Category");
      //   return false;
      // } else if (
      //   FormState.values.pId === "" ||
      //   typeof FormState.values.pId === "undefined" ||
      //   FormState.values.pId === null ||
      //   FormState.values.pId === "0"
      // ) {
      //   swal("Please select valid Payment Type");
      //   return false;
    } else return true;
  };

  const SoShow = (testId) => {
    setPdfLoading(true);
    // if (id === "") {
    //   //setPdfLoading(false);
    //   history.push(`${process.env.PUBLIC_URL}/view/Master/EmployeeMasterList`);
    // }
    axios
      .get(
        `${GetApiLink()}api/employeemaster/${testId}`,
        getRequestConfig({
          params: null,
        })
      )
      .then((response) => {
        setPdfLoading(false);
        console.log(" Employee Master Data Show Get: ", response);
        //console.log('A',response.data.response.error);
        if (typeof response.data.error != "undefined" && response.data.error == "true") {
          swal({
            icon: "error",
            title: "Opps!!!",
            text: response.data.message,
            type: "error",
          }).then(function () {
            history.push(`${process.env.PUBLIC_URL}/view/Master/EmployeeMasterList`);
          });
        } else {
          // if (response.data.response.isauth == "_AUTHFAILED") {
          //   history.push("/auth/logout");
          // } else {
          if (type === "add") {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                id: response.data.maxId,
              },
            }));
          } else {
            setFormState((FormState) => ({
              ...FormState,
              values: {
                ...FormState.values,
                ...response.data.data,
              },
            }));
          }
          //}
        }
      })
      .catch((error) => {
        setPdfLoading(false);
        console.log("Employee Master Show Error: ", error);
        //setCallError(`Material Error :${error.message} Refresh it Again`);
      });
    //setPdfLoading(false);
  };
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files[0]);
      setAadharFile(e.target.files[0]);
    }
  };
  const handleChange = (event) => {
    event.persist();

    setFormState((FormState) => ({
      ...FormState,
      values: {
        ...FormState.values,
        [event.target.name]: event.target.value,
      },
    }));
    if (event.target.type == "file") {
      console.log("Image ", aadharFile);
    }
  };

  const handleCityChange = (event) => {
    event.persist();
    setFormCityState((FormCityState) => ({
      ...FormCityState,
      values: {
        ...FormCityState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleStateChange = (event) => {
    event.persist();
    setFormStateState((FormStateState) => ({
      ...FormStateState,
      values: {
        ...FormStateState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleCountryChange = (event) => {
    event.persist();
    setFormCountryState((FormCountryState) => ({
      ...FormCountryState,
      values: {
        ...FormCountryState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };
  function handleEnter(event, search = false) {
    if (event.keyCode === 13 || keysPressed["Enter"]) {
      const form = event.target.form;
      if (
        form !== null &&
        typeof form !== "undefined" &&
        event.target !== "" &&
        typeof event.target !== "undefined" &&
        event.target !== null
      ) {
        const index = Array.prototype.indexOf.call(form, event.target);
        if (form.elements.length > index + 1) {
          const NextIndex = form.elements[index + 1].disabled
            ? event.target.type == "button"
              ? index + 1
              : index + 2
            : index + 1;
          if (form.elements.length > NextIndex) {
            form.elements[NextIndex].focus();
          }
        }
        event.preventDefault();
      }
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    handleSaveEntry();
    if (type == "view") {
      history.push(`${process.env.PUBLIC_URL}/view/Master/EmployeeMaster/edit/${FormState.values.id}`);
    }
  };
  const handleDelete = (event) => {
    event.preventDefault();
    handleDeleteEntry(FormState.values.id);
  };
  const handleCancel = (event) => {
    event.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/view/Master/EmployeeMasterList`);
  };

  const handleCitySubmit = (event) => {
    event.preventDefault();
    handleCitySaveEntry();
  };

  const handleStateSubmit = (event) => {
    event.preventDefault();
    handleStateSaveEntry();
  };

  const handleCountrySubmit = (event) => {
    event.preventDefault();
    handleCountrySaveEntry();
  };
  useEffect(() => {
    fetchCityMasterList();
    fetchCountryList();
    fetchStateMasterList();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  useEffect(() => {
    if (type === "edit" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "view" && typeof id !== "undefined") {
      SoShow(id);
    } else if (type === "add") {
      SoShow("0");
    }
  }, [type]);
  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-md">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <OverlineTitle tag="span" className="preview-title-lg">
                {" "}
                Employee Master
              </OverlineTitle>

              <form encType="multipart/form-data">
                <Row className="gy-4">
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        ID:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.id || ""}
                          name={"id"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Employee Code:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.eCode || ""}
                          name={"eCode"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Emplyee Category:
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          style={{ width: "85%" }}
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.eCategory || ""}
                          name={"eCategory"}
                        >
                          <option>Select</option>
                          <option value="Tester">Tester</option>
                          <option value="Approver">Approver</option>
                          <option value="Operator">Operator</option>
                          <option value="Receptionist">Receptionist</option>
                          <option value="Manager">Manager</option>
                          <option value="Chairman">Chairman</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Employee Name:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.eName || ""}
                          name={"eName"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup></FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Contact Number:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.contactOne || ""}
                          name="contactOne"
                        />
                        {/* <input className="form-control form-control-sm" type="text" id="default-0" placeholder="" /> */}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Alternative Number:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.contactTwo || ""}
                          name="contactTwo"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Email:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.email || ""}
                          name="email"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="6">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Address:
                      </Label>
                      <div className="form-control-wrap">
                        <textarea
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          cols={2}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.address || ""}
                          name="address"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        City:
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.city || ""}
                          name="city"
                        >
                          <option>Select</option>
                          {CityList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.CityName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        State:
                      </Label>
                      <div className="form-control-wrap">
                        <select
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.state || ""}
                          name="state"
                        >
                          <option>Select</option>
                          {StateList.map((m) => {
                            return (
                              <option value={m.id} key={m.id}>
                                {m.StateName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row className="gy-4">
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Aadhar Card No:
                      </Label>
                      <div className="form-control-wrap">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input form-control"
                            id="aadharCardFile"
                            onChange={
                              handleChange
                              //onChangePicture
                              //onFileChange(e);
                              //setAadharFile(e.target.files[0]);
                            }
                            
                            onKeyDown={handleEnter}
                            value={FormState.values.aadharPath || ""}
                            name="aadharPath"
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Qualification Certificate:
                      </Label>
                      <div className="form-control-wrap">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input form-control"
                            id="certificateFile"
                            onChange={(e) => {
                              handleChange(e);
                              //setQualificationFile(e.target.files[0]);
                            }}
                            onKeyDown={handleEnter}
                            value={FormState.values.qualPath || ""}
                            name="qualPath"
                          />
                          <Label className="custom-file-label" htmlFor="qualPath">
                            {qualificationFile === "" ? FormState.values.qualPath : qualificationFile}
                          </Label>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row className="gy-4">
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Blood Group:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.bloodGrp || ""}
                          name="bloodGrp"
                        />
                        {/* <input className="form-control form-control-sm" type="text" id="default-0" placeholder="" /> */}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Date of Joining:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="date"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.DOJ || ""}
                          name="DOJ"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Bank Name:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.bankName || ""}
                          name="bankName"
                        />
                        {/* <input className="form-control form-control-sm" type="text" id="default-0" placeholder="" /> */}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        Account Number:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.accNumber || ""}
                          name="accNumber"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label htmlFor="default-0" className="form-label">
                        IFSC Code:
                      </Label>
                      <div className="form-control-wrap">
                        <input
                          disabled={type == "view" ? true : false}
                          className="form-control form-control-sm"
                          type="text"
                          id="default-0"
                          placeholder=""
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onKeyDown={handleEnter}
                          value={FormState.values.ifscCode || ""}
                          name="ifscCode"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="gy-4">
                  <Col sm="2">
                    <Button
                      color="success"
                      name="Save"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                  <Col sm="2">
                    <Button color="danger" name="Delete">
                      Delete
                    </Button>
                  </Col>
                  <Col sm="2">
                    <Button
                      color="primary"
                      name="Cancel"
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </form>
              <Backdrop className={classes.backdrop} open={PdfLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default EmployeeMaster;
